import React, { useContext } from 'react';
import {PermissionsContext, PermissionsProvider} from './PermissionsProvider';
import Grid from "@mui/material/Grid2";

interface ProtectedComponentProps {
    requiredPermission: string;
    children: React.ReactNode;
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({ requiredPermission, children }) => {
    const { userPermissions, loading } = useContext(PermissionsContext);

    if (loading) {
        // Show a loading spinner while permissions are loading
        return (
            <Grid size={12}>
                <div className="bg-gray-100 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-md text-center min-w-full">
                        <p className="text-gray-600 text-lg">Loading permissions...</p>
                    </div>
                </div>
            </Grid>

        );
    }

    // Check if the user has the required permission
    const hasPermission = userPermissions.includes(requiredPermission);

    if (!hasPermission) {
        // Render the access denied message
        return (
            <Grid size={12}>
                <div className="bg-gray-100 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-md text-center min-w-full">
                        <svg className="w-16 h-16 text-red-600 mx-auto mb-4" fill="none" stroke="currentColor"
                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2V7m0 4v4m0-4a2 2 0 104 0 2 2 0 00-4 0m0 0a2 2 0 11-4 0 2 2 0 014 0z"></path>
                        </svg>
                        <h1 className="text-2xl font-semibold text-gray-800 mb-4">Access Denied</h1>
                        <p className="text-gray-600 mb-4">
                            You don't have access to this module. Please contact the admin if you believe this
                            is a mistake.
                        </p>
                        <button
                            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition">
                            Contact Admin
                        </button>
                    </div>
                </div>
            </Grid>
        );
    }

    // If the user has permission, render the children (protected content)
    return <>{children}</>;
};

export default ProtectedComponent;