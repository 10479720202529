import React from "react";
import ProtectedComponent from "../../../components/ProtectedComponent";
import PageTileAndBreadCrumb from "../../../components/PageTileAndBreadCrumb";
import Grid from "@mui/material/Grid2";
import {Card, CardContent} from "@mui/material";

const WindMaintenance: React.FC = () => {
    const required_module_id = 'wind_maintenance';
    return (
        <Grid container spacing={2}>
            <PageTileAndBreadCrumb showAssetDropDown={true} title={'Maintenance'}></PageTileAndBreadCrumb>
            <ProtectedComponent requiredPermission={required_module_id}>
                <Grid size={12}>
                    <Card>
                        <CardContent>
                            <p>You have access to this module</p>
                        </CardContent>
                    </Card>
                </Grid>
            </ProtectedComponent>
        </Grid>
    )
}

export default WindMaintenance;