// slices/weatherSlice.ts
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from "../utils/interceptor";


// Define the initial state type
interface DataState {
    loading: boolean;
    pcData: any[]; // Replace 'any[]' with the actual type of your data if needed
    error: string | null;
    oemPCData: any[],
}

const initialState: DataState = {
    loading: false,
    pcData: [],
    error: null,
    oemPCData: [],
};


export const fetchPowerCurveData = createAsyncThunk('data/powerCurveData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return response.data;
});

export const fetchOEMPowerCurveData = createAsyncThunk('data/oemPowerCurveData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return response.data;
});

const pcSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        // This action resets the state back to the initial state
        resetPCState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPowerCurveData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPowerCurveData.fulfilled, (state, action) => {
                state.loading = false;
                state.pcData = action.payload;
            })
            .addCase(fetchPowerCurveData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to post data';
            })
            .addCase(fetchOEMPowerCurveData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOEMPowerCurveData.fulfilled, (state, action) => {
                state.loading = false;
                state.oemPCData = action.payload;
            })
            .addCase(fetchOEMPowerCurveData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to post data';
            });
    },
});


export const { resetPCState } = pcSlice.actions;
export default pcSlice.reducer