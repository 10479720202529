import React, {useEffect, useMemo, useState} from "react";
import Grid from "@mui/material/Grid2";
import {Card, CardContent} from "@mui/material";
import AssetsTab from "./AssetsTab";
import {resetAssetCrudState} from "../../slices/assetCrudSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../store";
import TabComponent from "../../components/Tabs";
import UsersTab from "./UsersTab";
import {resetUserCrudState} from "../../slices/userCrudSlice";
import PageTileAndBreadCrumb from "../../components/PageTileAndBreadCrumb";
import {tabList} from "../../data/tabs/admin";

const Settings: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const dispatch = useDispatch<AppDispatch>()
    const handleTabChange = (newValue: number) => {
        setSelectedTab(newValue); // Update the state with the selected tab
    };

    useEffect(() => {
        dispatch(resetAssetCrudState());
        dispatch(resetUserCrudState())
    }, []);

    return (
        <Grid container spacing={2}>
            <PageTileAndBreadCrumb title={'Settings'}></PageTileAndBreadCrumb>

            <Grid size={12}>
                <TabComponent
                    onTabChange={handleTabChange}
                    tabList={tabList}
                />
            </Grid>

            {
                selectedTab === 0 &&
                <Grid size={12}>
                    <Card>
                        <CardContent>
                            <UsersTab />
                        </CardContent>
                    </Card>
                </Grid>
            }

            {
                selectedTab === 1 &&
                <Grid size={12}>
                    <Card>
                        <CardContent>
                            <AssetsTab />
                        </CardContent>
                    </Card>
                </Grid>
            }
        </Grid>
    )
}

export default Settings;