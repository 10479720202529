import React, {useEffect} from 'react';
import './App.css';
import {Route, BrowserRouter as Router, Routes, useNavigate} from "react-router-dom";
import SignIn from "./pages/auth/SignIn";
import PageLayout from "./components/PageLayout";

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<SignIn/>}/>
                <Route
                    path="*"
                    element={
                        <PageLayout></PageLayout>
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;