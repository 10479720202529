import React, {useEffect, useMemo, useState} from "react";
import Grid from "@mui/material/Grid2";
import {Card, CardContent} from "@mui/material";
import AGDataTable from "../../../components/AGDataTable";
import GoogleMap from "../../../components/Map";
import {ColDef} from "ag-grid-enterprise";
import {Asset, Device} from "../../../interfaces/asset";
import {MapData} from "../../../interfaces/map";
import {fetchAssetData, resetAssetState} from "../../../slices/assetSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../slices";
import {AppDispatch} from "../../../store";
import {useNavigate} from "react-router-dom";


const DevicesTab: React.FC = () => {
    const {selectedAssetId} = useSelector((state: RootState) => state.global);
    const {data, loading, error} = useSelector((state: RootState) => state.assetData)
    const [assetDetails, setAssetDetails] = useState<Asset>();
    const [assetDevicesData, setAssetDevicesData] = useState<Device[]>([]);
    const [assetMapData, setAssetsMapData] = useState<MapData[]>([])
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();

    const columns: ColDef[] = [
        {field: 'id', headerName: 'Asset ID', maxWidth: 200, hide: true},
        {
            field: 'name', headerName: 'Asset Name', flex: 1,
            cellRenderer: (params: any) => {
                return (
                    <button
                        onClick={() => navigate(`/device-details/${params.data.id}`)}>
                        {params.value}
                    </button>
                );
            }
        },
        {field: 'capacity', headerName: 'Capacity (MW)', flex: 1},
        {field: 'oem', headerName: 'OEM', flex: 1},
        {field: 'latitude', headerName: 'Latitude', flex: 1},
        {field: 'longitude', headerName: 'Longitude', flex: 1},
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            editable: true,
            filter: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
        };
    }, []);

    useEffect(() => {
        if (!loading && data) {
            setAssetDetails(data)
            const tempAssetData : Device[] = []
            const tempMapData: MapData[] = []
            data.devices.forEach(device => {
                tempAssetData.push({
                    id: device.id,
                    latitude: device.latitude,
                    longitude: device.longitude,
                    name: device.name,
                    oem: device.oem ? device.oem : 'Vestas',
                    capacity: device.capacity ? device.capacity : 5.25,
                })
                tempMapData.push({latitude: device.latitude, longitude: device.longitude, name: device.name})
            })
            setAssetDevicesData(tempAssetData)
            setAssetsMapData(tempMapData)
        }
    }, [loading, data]);

    useEffect(() => {
        if (selectedAssetId) {
            dispatch(fetchAssetData(`asset/${selectedAssetId}`))
        } else {
            dispatch(resetAssetState())
        }
    }, [selectedAssetId]);

    return (
        <>
            <Grid size={12}>
                <Card>
                    <CardContent>
                        <AGDataTable
                            title={'Device Information'}
                            rowData={assetDevicesData}
                            colDefs={columns}
                            defaultColDef={defaultColDef}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid size={12}>
                <div style={{height: '60vh', width: '100%'}}>
                    <GoogleMap showInfoWindow={true}
                               infoWindowHeaderContent={'Device Information'}
                               coords={assetMapData} defaultZoom={12} />
                </div>
            </Grid>
        </>
    )
}

export default DevicesTab;