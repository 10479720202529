import * as Highcharts from "highcharts";

let windChartOptions: Highcharts.Options = {
    chart: {
        height: 600,
    },
    title: {
        text: '',
    },
    xAxis: {
        categories: [],
        crosshair: true,
    },
    yAxis: [
        {
            title: {
                text: 'Generation (MWh)'
            }
        },
        {
            opposite: true,
            title: {
                text: 'Wind Speed (m/s)'
            }
        }
    ],
    tooltip: {
        shared: true, // Enable shared tooltip
        valueDecimals: 2,
        useHTML: true,  // Enable HTML in the tooltip
        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Semi-transparent black background
        borderColor: '#FF5733', // Orange border color
        borderRadius: 10, // Rounded corners
        style: {
            color: '#FFFFFF',  // White text
            fontSize: '14px',  // Slightly larger text
        },
        formatter: function () {
            let tooltipHtml = `<div style="text-align: left;"><b>${this.x}</b><br/>`;
            this.points?.forEach(point => {
                // Safely access point.y, use 0 as fallback if null or undefined
                const yValue = point.y?.toFixed(2) ?? '0.00';
                tooltipHtml += `<span style="color: ${point.series.color};">● </span><b>${point.series.name}</b>: ${yValue}<br/>`;
            });
            tooltipHtml += `</div>`;
            return tooltipHtml;
        }
    },
    series: [
        {
            type: 'spline',
            name: 'Wind Speed',
            yAxis: 1,
            data: [],
        },
        {
            type: 'column',
            name: 'Actual Generation',
            yAxis: 0,
            data: [],
        },
        {
            type: 'column',
            name: 'Estimated Generation',
            yAxis: 0,
            data: [],
        },
        {
            type: 'column',
            name: 'Lost Generation',
            yAxis: 0,
            data: [],
        },
    ],
};

export default windChartOptions;