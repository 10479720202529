import React, {useEffect, useMemo, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {ShowChart, TableChart} from "@mui/icons-material";
import {HighchartsReact} from "highcharts-react-official";
import * as Highcharts from "highcharts";
import windChartOptions from "../../data/chartOptions/fleet_overview_wind_active_pwr_wind_speed";
import {fetchLast15DaysKpiData} from "../../slices/kpiSlice";
import {getLast15Days} from "../../utils/dateUtils";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import groupBy from "lodash/groupBy";
import sumBy from "lodash/sumBy";
import {sortBy} from "lodash";
import {KPI} from "../../interfaces/kpi";
import DataTable from "../../components/DataTable";
import {ColDef} from "ag-grid-enterprise";
import {Tooltip} from "@mui/material";
import {defaultCols} from "../../utils/ag-table";
import windChartTBAChartOptions from "../../data/chartOptions/fleet_overview_tba";


const AvailabilitiesTrends: React.FC = (props: any) => {
    const [selectedView, setSelectedView] = useState<string>('chart')
    const {data, loading, error} = useSelector((state: RootState) => state.kpiData).kpiData.last15DaysKpiData;
    const [chartData, setChartData] = useState<KPI[]>([])
    const [tableData, setTableData] = useState<any[]>([])

    const windChart3ComponentRef = useRef<HighchartsReact.RefObject>(null);

    const columns: ColDef[] = [
        {
            field: 'date',
            cellRenderer: "agGroupCellRenderer",
            headerName: 'Date',
        },
        {
            field: 'eba_daily',
            headerName: 'EBA (%)',
            valueFormatter: (params) => {
                return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
            }
        },
        {
            field: 'tba_daily',
            headerName: 'TBA (%)',
            valueFormatter: (params) => {
                return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
            }
        },
    ]

    const detailsTableColumns: ColDef[] = [
        {
            field: 'asset_name',
            headerName: 'Asset Name',
        },
        {
            field: 'wind_speed',
            headerName: 'Wind Speed (m/s)',
            valueFormatter: (params) => {
                return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
            }
        },
        {
            field: 'active_power',
            headerName: 'Actual Generation (MWh)',
            valueFormatter: (params) => {
                return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
            }
        },
        {
            field: 'estimated_power',
            headerName: 'Estimated generation (MWh)',
            valueFormatter: (params) => {
                return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
            }
        },
        {
            field: 'lost_power',
            headerName: 'Lost generation (MWh)',
            valueFormatter: (params) => {
                return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
            }
        },
        {
            field: 'lost_percentage',
            headerName: 'Lost percentage (%)',
            cellClassRules: {
                // Conditional CSS classes for status column
                'cell-red': params => params.value >= 10,
                'cell-green': params => params.value < 10,
            },
            valueFormatter: (params) => {
                return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
            }
        },
        {
            field: 'capacity_factor',
            headerName: 'Capacity factor (%)',
            valueFormatter: (params) => {
                return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
            }
        }
    ]

    const gridOptions = {
        masterDetail: true, // Enable master/detail mode
        detailCellRendererParams: {
            detailGridOptions: {
                pagination: false,
                defaultColDef: {
                    flex: 1,
                },
                height: 100,
                // domLayout: 'autoHeight',
                columnDefs: detailsTableColumns, // Reuse the same column definitions for the detail grid
            },
            getDetailRowData: (params: any) => {
                // Pass detail row data
                let groupedByDateKpiData = groupBy(data, 'date')
                let groupedByAssetKpiData = groupBy(groupedByDateKpiData[params.data.date], 'asset_id')
                let detailsData = []
                for (let groupedByAssetKpiDataKey in groupedByAssetKpiData) {
                    detailsData.push(createKPIData(groupedByAssetKpiDataKey, groupedByAssetKpiData[groupedByAssetKpiDataKey]))
                }
                params.successCallback(detailsData);
            },
        },
    };

    const defaultColDef = useMemo(() => defaultCols, []);

    const handleIconClick = (buttonName: string) => {
        setSelectedView(buttonName)
        if (buttonName === 'chart') {
            drawChart(chartData)
        }
    }

    useEffect(() => {
        windChart3ComponentRef.current?.chart.showLoading()
        if(!loading && data.length > 0) {
            setChartData(data)
            drawChart(data)
        }
    }, [data, loading]);

    const drawChart = (chartData: any) => {
        let tempCategories: string[] = []
        let ebaSeries: any[] = []
        let tbaSeries: number[] = []

        let newData = []

        let groupedByDateKpiData = groupBy(chartData, 'date')

        for (let groupedByDateKpiDataKey in groupedByDateKpiData) {
            let temObj = createKPIData(groupedByDateKpiDataKey, groupedByDateKpiData[groupedByDateKpiDataKey])
            newData.push(temObj)
        }

        newData = sortBy(newData, 'date')

        newData.map((kpi) => {
            tempCategories.push(kpi.date)
            ebaSeries.push(kpi.eba_daily)
            tbaSeries.push(kpi.tba_daily)
        })

        setTableData(newData);

        setTimeout(() => {
            const windTBAChart = windChart3ComponentRef.current?.chart;
            if (windTBAChart) {
                // Update the series data
                if (windTBAChart.series[0]) {
                    windTBAChart.series[0].setData(tbaSeries);
                    windTBAChart.series[1].setData(ebaSeries);
                }

                // Update the categories
                if (windTBAChart.xAxis[0]) {
                    windTBAChart.xAxis[0].setCategories(tempCategories);
                }
                windTBAChart.hideLoading()
            }
        })
    }

    const createKPIData = (key: any, data: any) => {
        return {
            date: key,
            asset_name: data[0].asset_name,
            tba_numerator: sumBy(data, 'tba_numerator'),
            tba_denominator: sumBy(data, 'tba_denominator'),
            eba_numerator: sumBy(data, 'eba_numerator'),
            eba_denominator: sumBy(data, 'eba_denominator'),
            tba_daily: (sumBy(data, 'tba_numerator') / sumBy(data, 'tba_denominator') * 100),
            eba_daily: (sumBy(data, 'eba_numerator') / sumBy(data, 'eba_denominator') * 100)
        }
    }

    return (
        <>
            <div className={'mb-4 flex justify-between items-center align-middle'}>
                <Typography variant={'h6'}>Availabilities Trends (Last 15 days)</Typography>
                <div style={{border: '1px solid'}}>
                    <Tooltip title={'View Chart'}>
                        <IconButton sx={{borderRadius: 0, borderRight: '1px solid'}} onClick={() => handleIconClick('chart')}
                                    color={selectedView === 'chart' ? 'primary' : 'default'}
                                    style={{
                                        backgroundColor: selectedView === 'chart' ? 'rgba(0, 0, 0, 0.04)' : '',
                                    }}
                        >
                            <ShowChart></ShowChart>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'View Table'}>
                        <IconButton sx={{borderRadius: 0}} onClick={() => handleIconClick('table')}
                                    color={selectedView === 'table' ? 'primary' : 'default'}
                                    style={{backgroundColor: selectedView === 'table' ? 'rgba(0, 0, 0, 0.04)' : ''}}>
                            <TableChart></TableChart>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {
                selectedView === 'chart' &&
                <HighchartsReact
                    highcharts={Highcharts}
                    ref={windChart3ComponentRef}
                    options={windChartTBAChartOptions}>
                </HighchartsReact>
            }
            {
                selectedView === 'table' &&
                <DataTable
                    gridOptions={gridOptions}
                    rowData={tableData}
                    colDefs={columns}
                    defaultColDef={defaultColDef}/>
            }
        </>
    )
}

export default AvailabilitiesTrends