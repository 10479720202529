// Function to calculate the center
import {MapData} from "../interfaces/map";
interface LatLng {
    lat: number;
    lng: number;
}

const calculateCenter = (markers: MapData[]): LatLng => {
    const totalMarkers = markers.length;

    // Sum all latitudes and longitudes
    const sumCoordinates = markers.reduce(
        (acc, marker) => {
            acc.lat += marker.latitude;
            acc.lng += marker.longitude;
            return acc;
        },
        { lat: 0, lng: 0 }
    );

    // Calculate the average latitude and longitude
    return {
        lat: sumCoordinates.lat / totalMarkers,
        lng: sumCoordinates.lng / totalMarkers,
    };
};

export default calculateCenter;