import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid2";
import {Card} from "@mui/material";
import KpiWidget from "../../components/KpiWidget";
import sumBy from "lodash/sumBy";
import {fetchYesterdayKpiData} from "../../slices/kpiSlice";
import {getLast7Days, getYesterday} from "../../utils/dateUtils";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {formatNumber} from "../../utils/numberFormatter";
import {fetchDataAvailabilityData, resetDataAvailabilityDataState} from "../../slices/dataAvailabilitySlice";
import {KPIData, KPIs} from "../../interfaces/kpi";

const initialState: KPIData = {
    last7DaysData: {
        generation: null,
        lostGeneration: null,
        windSpeed: null,
        capacityFactor: null,
        revenue: null,
        potentialRevenue: null,
        eba: null,
        tba: null,
        dataAvailability: null,
        estimatedGeneration: null,
    },
    yesterdayData: {
        generation: null,
        lostGeneration: null,
        windSpeed: null,
        capacityFactor: null,
        revenue: null,
        potentialRevenue: null,
        eba: null,
        tba: null,
        dataAvailability: null,
        estimatedGeneration: null,
    },
}

const Kpi: React.FC = (props) => {
    const [kpiData, setKpiData] = useState<KPIData>(initialState);
    const dispatch = useDispatch<AppDispatch>()
    const {data: last7DaysData, loading: last7DaysLoading, error: last7DaysError} = useSelector((state: RootState) => state.kpiData).kpiData.last7DaysKpiData;
    const {data: yesterdayData, loading: yesterdayLoading, error: yesterdayError} = useSelector((state: RootState) => state.kpiData).kpiData.yesterdayKpiData;
    const {dataAvailabilityData, dataAvailabilityLoading, dataAvailabilityError} = useSelector((state: RootState) => state.availabilityData);

    useEffect(() => {
        if (last7DaysData && last7DaysData.length > 0 && yesterdayData && dataAvailabilityData && dataAvailabilityData.length > 0) {
            setKpiData({last7DaysData: generateKPIData(last7DaysData, 'last7Days'), yesterdayData: generateKPIData(yesterdayData, 'yesterday')});
        }
    }, [last7DaysData, yesterdayData, dataAvailabilityData]);

    const generateKPIData = (data: any[], cadence: string) => {
        const yesterdayDAPoint = dataAvailabilityData.filter(dataPoint => dataPoint.date == getYesterday()[0])
        const yesterdayDA = sumBy(yesterdayDAPoint, 'total_count') / sumBy(yesterdayDAPoint, 'ideal_count') * 100
        const DA = sumBy(dataAvailabilityData, 'total_count') / sumBy(dataAvailabilityData, 'ideal_count') * 100
        const temp: KPIs = {
            capacityFactor: +(sumBy(data, 'cf_numerator') / sumBy(data, 'cf_denominator') * 100).toFixed(2),
            dataAvailability: cadence === 'last7Days' ? +DA.toFixed(2) : +yesterdayDA.toFixed(2),
            eba: +(sumBy(data, 'eba_numerator') / sumBy(data, 'eba_denominator') * 100).toFixed(2),
            estimatedGeneration: +(sumBy(data, 'estimated_power') / 6000000).toFixed(2),
            generation: +(sumBy(data, 'active_power') / 6000000).toFixed(2),
            lostGeneration: +(sumBy(data, 'lost_power') / 6000000).toFixed(2),
            potentialRevenue: formatNumber(sumBy(data, 'potential_revenue')),
            revenue: formatNumber(sumBy(data, 'actual_revenue')),
            tba: +(sumBy(data, 'tba_numerator') / sumBy(data, 'tba_denominator') * 100).toFixed(2),
            windSpeed: +(sumBy(data, 'wind_speed') / data.length).toFixed(2)
        }
        return temp
    }

    useEffect(() => {
        dispatch(fetchYesterdayKpiData(`wind-daily-kpi?start_date=${getYesterday()[0]}&end_date=${getYesterday()[1]}&level=fleet`))
        dispatch(fetchDataAvailabilityData(`wind-data-availability?start_date=${getLast7Days()[0]}&end_date=${getLast7Days()[1]}&level=fleet`))
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetDataAvailabilityDataState())
        }
    }, []);

    return (
        <>
            {/* KPIs */}
            <Grid size={2}>
                <Card>
                    <KpiWidget last7DaysColor={'red'}
                               loading={last7DaysLoading}
                               unit={'GWh'}
                               title={'Actual Generation'}
                               yesterdayValue={kpiData?.yesterdayData?.generation}
                               last7DaysValue={kpiData?.last7DaysData?.generation}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'GWh'} title={'Potential Generation'}
                               loading={last7DaysLoading}
                               yesterdayValue={kpiData?.yesterdayData?.estimatedGeneration}
                               last7DaysValue={kpiData?.last7DaysData?.estimatedGeneration}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'GWh'} title={'Lost Power'}
                               loading={last7DaysLoading}
                               yesterdayValue={kpiData?.yesterdayData?.lostGeneration}
                               last7DaysValue={kpiData?.last7DaysData?.lostGeneration}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'m/s'} title={'Avg Wind Speed'}
                               loading={last7DaysLoading}
                               yesterdayValue={kpiData?.yesterdayData?.windSpeed}
                               last7DaysValue={kpiData?.last7DaysData?.windSpeed}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'%'} title={'Capacity Factor'}
                               loading={last7DaysLoading}
                               yesterdayValue={kpiData?.yesterdayData?.capacityFactor}
                               last7DaysValue={kpiData?.last7DaysData?.capacityFactor}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'%'} title={'Time Based Availability'}
                               loading={last7DaysLoading}
                               yesterdayValue={kpiData?.yesterdayData?.tba}
                               last7DaysValue={kpiData?.last7DaysData?.tba}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'%'} title={'Energy Based Availability'}
                               loading={last7DaysLoading}
                               yesterdayValue={kpiData?.yesterdayData?.eba}
                               last7DaysValue={kpiData?.last7DaysData?.eba}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'$'} title={'Actual Revenue'}
                               loading={last7DaysLoading}
                               yesterdayValue={kpiData?.yesterdayData?.revenue}
                               last7DaysValue={kpiData?.last7DaysData?.revenue}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'$'} title={'Potential Revenue'}
                               loading={last7DaysLoading}
                               yesterdayValue={kpiData?.yesterdayData?.potentialRevenue}
                               last7DaysValue={kpiData?.last7DaysData?.potentialRevenue}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'%'} title={'Data Availability'}
                               loading={last7DaysLoading}
                               yesterdayValue={kpiData?.yesterdayData?.dataAvailability}
                               last7DaysValue={kpiData?.last7DaysData?.dataAvailability}></KpiWidget>
                </Card>
            </Grid>
        </>
    )
}

export default Kpi