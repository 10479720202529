import Grid from "@mui/material/Grid2";
import {Card, CardContent} from "@mui/material";
import AGDataTable from "../../components/AGDataTable";
import WindPowerIcon from "@mui/icons-material/WindPower";
import React, {useEffect, useMemo, useState} from "react";
import {MapData} from "../../interfaces/map";
import {ColDef} from "ag-grid-enterprise";
import {useNavigate} from "react-router-dom";
import {TableData} from "../../interfaces/fleet_overview";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {defaultCols} from "../../utils/ag-table";

const AssetsOverview = () => {
    const [windAssets, setWindAssets] = useState<MapData[]>([])
    const navigate = useNavigate()
    const columns: ColDef[] = [
        {field: 'id', headerName: 'Asset ID', maxWidth: 200, hide: true},
        {
            field: 'asset_name',
            headerName: 'Asset Name',
            flex: 1,
            cellRenderer: (params: any) => {
                return (
                    <button
                        onClick={() => navigate(`/asset-details/${params.data.id}`)}>
                        {params.value}
                    </button>
                );
            }
        },
        {field: 'state', headerName: 'State', flex: 1},
        {field: 'currency', headerName: 'Currency', flex: 1},
        {field: 'ppa', headerName: 'PPA', flex: 1},
        {field: 'altitude', headerName: 'Altitude', flex: 1},
        {field: 'total_devices', headerName: 'Total Devices', flex: 1},
        {field: 'asset_timezone', headerName: 'Asset Timezone', flex: 1},
        {field: 'subscribed', headerName: 'Subscribed', flex: 1},
        {field: 'latitude', headerName: 'Latitude', flex: 1},
        {field: 'longitude', headerName: 'Longitude', flex: 1},
        {field: 'user_role', headerName: 'Access', flex: 1},
    ];
    const {
        userAssetsData,
        userBasicDataError,
        userAssetsLoading,
    } = useSelector((state: RootState) => state.userData);

    useEffect(() => {
        if (!userAssetsLoading && userAssetsData.length > 0) {
            let temp: MapData[] = []
            let tempWindTableData: TableData[] = []
            let tempSolarTableData: TableData[] = []
            userAssetsData.forEach(assetData => {
                const tempTableObj: TableData = {
                    id: assetData.id,
                    asset_name: assetData.name,
                    asset_type: assetData.asset_type,
                    latitude: assetData.latitude,
                    longitude: assetData.longitude,
                    user_role: assetData.role_display_name,
                    state: assetData.state,
                    ppa: assetData.ppa,
                    altitude: assetData.altitude,
                    total_devices: assetData.total_devices,
                    asset_timezone: assetData.asset_timezone,
                    subscribed: assetData.subscribed,
                    currency: assetData.currency,
                }

                if (tempTableObj.asset_type === 'wind') {
                    tempWindTableData.push(tempTableObj)
                }

                if (tempTableObj.asset_type === 'solar') {
                    tempSolarTableData.push(tempTableObj)
                }
            })
            setWindAssets(tempWindTableData)
        }
    }, [userAssetsData, userAssetsLoading]);

    const defaultColDef = useMemo(() => defaultCols, []);

    return (
        <>
            <Grid size={12}>
                <Card>
                    <CardContent>
                        <AGDataTable
                            title={'Assets Overview'}
                            icon={<WindPowerIcon />}
                            rowData={windAssets}
                            colDefs={columns}
                            defaultColDef={defaultColDef} />
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}

export default AssetsOverview