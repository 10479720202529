import React, {useEffect, useMemo, useRef, useState} from "react";
import Grid from '@mui/material/Grid2';
import {Card, CardContent, Tooltip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import GoogleMap from "../../components/Map";
import {MapData} from "../../interfaces/map";
import {fetchLast15DaysKpiData, fetchLast7DaysKpiData} from "../../slices/kpiSlice";
import {getLast15Days, getLast7Days} from '../../utils/dateUtils';
import PageTileAndBreadCrumb from "../../components/PageTileAndBreadCrumb";
import Metadata from "./Metadata";
import Kpi from "./Kpi";
import AssetsOverview from "./AssetsOverview";
import GenerationTrends from "./GenerationTrends";
import AvailabilitiesTrends from "./AvailabilitiesTrends";
import AGDataTable from "../../components/AGDataTable";
import {fetchDevicesStatus} from "../../slices/statusSlice";
import {
    ColDef,
} from "ag-grid-enterprise";
import NavigationIcon from '@mui/icons-material/Navigation'; // Import MUI Icon
import {defaultCols} from "../../utils/ag-table";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const FleetOverview: React.FC = () => {
    const [assetMapData, setAssetsMapData] = useState<MapData[]>([])
    const dispatch = useDispatch<AppDispatch>()
    const [liveData, setLiveData] = useState<any[]>([])
    const defaultColDef = useMemo(() => defaultCols, []);
    const {
        userAssetsData,
        userBasicDataError,
        userAssetsLoading,
    } = useSelector((state: RootState) => state.userData);

    const {
        loading,
        statusData,
        error,
    } = useSelector((state: RootState) => state.liveData);

    const WindDirectionRenderer = (params: any) => {
        const windDirection = params.value;  // Wind direction in degrees

        // If the wind direction value is null or undefined, return null (empty cell)
        if (windDirection == null) {
            return 'N/A';
        }

        // Return JSX with MUI icon and Tailwind for layout and styling
        return (
            <div className="flex justify-end items-center space-x-2">
                {/* Display the wind direction value */}
                <span className="text-gray-700 font-medium text-right">{windDirection}°</span>

                {/* Display the wind direction icon */}
                <NavigationIcon
                    className="text-blue-500"  // Tailwind class for color (blue arrow)
                    style={{
                        transform: `rotate(${windDirection}deg)`,  // Rotate based on wind direction
                        transition: 'transform 0.3s ease',         // Smooth transition
                        fontSize: '24px'                           // Font size for the icon
                    }}
                />
            </div>
        );
    };

    // Custom renderer for the last 24 hours status with tooltips
    const LineRenderer = (params: any) => {
        const statusData = params.data.last24Hours; // Array of status data (144 points)

        // Create 144 segments (one for each 10-minute interval)
        let lineSegments: any = [];

        statusData.forEach((item: any, index: any) => {
            let classes = 'bg-yellow-500 bg-orange-500 bg-yellow-500'
            const color = item.status_color? 'inline-block h-2 cursor-pointer mt-4 bg-' + item.status_color.toLowerCase() + '-500': 'inline-block h-2 cursor-pointer mt-4 bg-gray-500';
            const time = new Date(item.time_stamp_asset).toLocaleString();
            const statusText = item.status_description;

            lineSegments.push(
                <Tooltip
                    key={index}
                    title={`${time} - ${statusText}`}
                    arrow>
                    <div
                        className={color}  // Tailwind classes
                        style={{
                            borderRight: `1px solid #fff`,
                            width: `${100 / statusData.length}%`,  // Each segment is equally sized
                        }}
                    ></div>
                </Tooltip>
            );
        })

        return <div className="flex">{lineSegments}</div>;
    };

    const columns: ColDef[] = [
        {
            field: 'assetName',
            headerName: 'Asset Name',
        },
        {
            field: 'deviceName',
            headerName: 'Device Name',
            cellRenderer: (params: any) => {
                let classes = 'text-yellow-500 text-orange-500 text-yellow-500'
                return (
                    <div className="flex items-center">
                        {params.data.available ? (
                            <ArrowUpwardIcon className={'text-' + `${params.data.statusColor.toLowerCase()}` + '-500 mr-2'}/>
                        ) : (
                            <ArrowDownwardIcon className={'text-' + `${params.data.statusColor.toLowerCase()}` + '-500 mr-2'}/>
                        )}
                        <span>{params.data.deviceName}</span>
                    </div>
                );
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            hide: true
        },
        {
            field: 'activePower',
            headerName: 'Active Power (kW)',
            cellRenderer: (params: any) => {
                return <ProgressBarCell {...params} />
            }
        },
        {
            field: 'windSpeed',
            headerName: 'Wind Speed (m/s)',
            cellClass: 'text-right',
            valueFormatter: (params) => {
                if (params.value) return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
                else return `N/A`;
            }
        },
        {
            field: 'windDirection',
            headerName: 'Wind Direction',
            cellClass: 'text-right',
            cellRenderer: WindDirectionRenderer,  // Custom cell renderer for wind direction
            valueFormatter: (params) => {
                if (params.value) return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
                else return `N/A`;
            }
        },
        {
            field: 'temperature',
            headerName: 'Temperature',
            cellClass: 'text-right',
            valueFormatter: (params) => {
                if (params.value) return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
                else return `N/A`;
            }
        },
        {
            field: 'estimatedPower',
            headerName: 'Estimated Power',
            hide: true
        },

        {
            field: 'statusColor',
            hide: true,
            headerName: 'Status Color',
        },
        {
            field: 'available',
            hide: true,
            headerName: 'Available',
        },
        {
            field: "last24Hours",
            headerName: 'Last 24 Hours Status',
            minWidth: 600,
            cellRenderer: LineRenderer,
        },
        {
            field: 'latestTimeStamp',
            headerName: 'Last updated',
            minWidth: 200,
            valueFormatter: (params) => {
                return new Date(params.data.latestTimeStamp).toLocaleString();
            }
        },
    ]

    const ProgressBarCell = (params: any) => {
        const powerPercentage = params.data.powerPercentage
        const activePower = params.data.activePower
        return (
            <div className="flex items-center space-x-4">
                {/* Display active power with fixed width */}
                <span className="w-16">{activePower}</span>
                {/* Progress bar with percentage on it */}
                <div className="relative w-full bg-gray-200 rounded h-4">
                    <div
                        className={`absolute inset-0 flex items-center justify-center text-white text-sm`}
                    >
                        {/* Display percentage on the bar */}
                        {powerPercentage.toFixed(2)}%
                    </div>
                    <div
                        className={`h-full rounded ${powerPercentage < 90 ? 'bg-red-500' : powerPercentage <= 95 ? 'bg-yellow-500' : powerPercentage > 95 ? 'bg-green-500' : ''}`}
                        style={{ width: `${powerPercentage === 0 ? 100 : powerPercentage}%` }}
                    ></div>
                </div>
            </div>
        );
    };

    useEffect(() => {

        if (!loading && statusData.length > 0) {
            setLiveData(statusData)
        }

    }, [loading, statusData]);

    useEffect(() => {
        if (!userAssetsLoading && userAssetsData.length > 0) {
            let temp: MapData[] = []
            userAssetsData.forEach(assetData => {
                const tempMapObj = {
                    name: assetData.name,
                    latitude: assetData.latitude,
                    longitude: assetData.longitude,
                }
                temp.push(tempMapObj)
            })
            setAssetsMapData(temp)
        }
    }, [userAssetsData, userAssetsLoading]);

    useEffect(() => {
        dispatch(fetchDevicesStatus('live-data'))
        dispatch(fetchLast7DaysKpiData(`wind-daily-kpi?start_date=${getLast7Days()[0]}&end_date=${getLast7Days()[1]}&level=fleet`))
        dispatch(fetchLast15DaysKpiData(`wind-daily-kpi?start_date=${getLast15Days()[0]}&end_date=${getLast15Days()[1]}&level=fleet`))
    }, []);

    return (
        <Grid container spacing={2}>
            <PageTileAndBreadCrumb title={'Overview'}></PageTileAndBreadCrumb>

            <Metadata></Metadata>

            <Grid container size={12}>
                <Kpi></Kpi>
            </Grid>

            <Grid size={12}>
                <Card>
                    <CardContent>
                        <AGDataTable
                            loading={loading}
                            defaultColDef={defaultColDef}
                            title={'Live data'}
                            rowData={liveData}
                            colDefs={columns}></AGDataTable>
                    </CardContent>
                </Card>
            </Grid>

            <Grid size={12}>
                <Card>
                    <CardContent>
                        <GenerationTrends></GenerationTrends>
                    </CardContent>
                </Card>
            </Grid>

            <Grid size={12}>
                <Card>
                    <CardContent>
                        <AvailabilitiesTrends></AvailabilitiesTrends>
                    </CardContent>
                </Card>
            </Grid>

            {/* Tables */}
            <AssetsOverview></AssetsOverview>

            {/* Map */}
            <Grid size={12}>
                <div style={{height: '60vh', width: '100%'}}>
                    <GoogleMap showInfoWindow={true}
                               infoWindowHeaderContent={'Site Information'}
                               coords={assetMapData} defaultZoom={5}></GoogleMap>
                </div>
            </Grid>
        </Grid>
    )
}

export default FleetOverview;