import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid2";
import {Skeleton} from "@mui/lab";

type KpiWidgetProps = {
    title?: string;
    yesterdayValue?: number | string | null;
    last7DaysValue?: number | string | null;
    unit?: string;
    yesterdayColor?: string;
    last7DaysColor?: string;
    loading?: boolean;
}

const KpiWidget: React.FC<KpiWidgetProps> = ({title, unit, yesterdayValue, last7DaysValue, yesterdayColor, last7DaysColor, loading}) => {
    const [last7DaysColorClass, setLast7DaysColorClass] = useState<string>('text-2xl font-bold text-gray-700');
    const [yesterdayColorClass, setYesterdayColorClass] = useState<string>('text-2xl font-bold text-gray-700');

    useEffect(() => {
        if (last7DaysColor === 'red') {
            setLast7DaysColorClass(`text-2xl font-bold text-red-700`);
        }
        if (last7DaysColor === 'green') {
            setLast7DaysColorClass(`text-2xl font-bold text-green-700`);
        }
    }, [last7DaysColor]);

    return (
        <div className={'px-4 pt-2 pb-2'}>
            <div className={'mb-4'}>
                {
                    loading &&
                    <Skeleton variant={"text"} animation="wave">
                        <h3 className="text-md font-bold text-gray-700">{title} ({unit})</h3>
                    </Skeleton>
                }

                {
                    !loading &&
                    <h3 className="text-md font-bold text-gray-700">{title} ({unit})</h3>
                }
            </div>
            <Grid container size={12}>
                <Grid size={6}>
                <div className="flex items-center justify-center w-full">
                        <div className="w-full">
                            {
                                loading &&
                                <Skeleton variant={"text"} animation="wave">
                                    <p className="text-sm font-medium text-gray-700">Yesterday</p>
                                    <p className={yesterdayColorClass}>{yesterdayValue ? yesterdayValue : 'N/A'}</p>
                                </Skeleton>
                            }

                            {
                                !loading &&
                                <>
                                    <p className="text-sm font-medium text-gray-700">Yesterday</p>
                                    <p className={yesterdayColorClass}>{yesterdayValue ? yesterdayValue : 'N/A'}</p>
                                </>
                            }
                        </div>
                </div>
                </Grid>

                {/* Last 7 Days */}
                <Grid size={6}>
                <div className="flex items-center justify-center w-full">
                        <div className="w-full">
                            {
                                loading &&
                                <Skeleton variant={"text"} animation="wave">
                                    <p className="text-sm font-medium text-gray-700">Last 7 days</p>
                                    <p className={last7DaysColorClass}>{last7DaysValue ? last7DaysValue : 'N/A'}</p>
                                </Skeleton>
                            }

                            {
                                !loading &&
                                <>
                                    <p className="text-sm font-medium text-gray-700">Last 7 days</p>
                                    <p className={last7DaysColorClass}>{last7DaysValue ? last7DaysValue : 'N/A'}</p>
                                </>
                            }
                        </div>
                </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default KpiWidget