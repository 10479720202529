import React, {useEffect, useState} from "react";
import KpiWidget from "../../../components/KpiWidget";
import Grid from "@mui/material/Grid2";
import {Card, CardContent} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../store";
import {fetchLast7DaysKpiData, fetchYesterdayKpiData} from "../../../slices/kpiSlice";
import {getLast15Days, getYesterday} from "../../../utils/dateUtils";
import {calculateAvailabilities, calculateCF, convertToGWh, formatNumber} from "../../../utils/numberFormatter";
import {
    fetchDataAvailabilityData,
    fetchLast7DaysDAData,
    fetchYesterdayDAData
} from "../../../slices/dataAvailabilitySlice";

const KPITab: React.FC = (props) => {
    const dispatch = useDispatch<AppDispatch>()
    const [last7DaysKPIs, setLast7DaysKPIs] = useState<any>()
    const [yesterdayKPIs, setYesterdayKPIs] = useState<any>()
    const {selectedAssetId} = useSelector((state: RootState) => state.global);
    const {loading: last7DaysLoading, error: last7DaysError, data: last7DaysData} = useSelector((state: RootState) => state.kpiData).kpiData.last7DaysKpiData;
    const {loading: yesterdayLoading, error: yesterdayError, data: yesterdayData} = useSelector((state: RootState) => state.kpiData).kpiData.yesterdayKpiData;
    const {loading: last7DaysDALoading, error: last7DaysDAError, aggregatedData: last7DaysDAData} = useSelector((state: RootState) => state.availabilityData).last7DaysData;
    const {loading: yesterdayDALoading, error: yesterdayDAError, aggregatedData: yesterdayDAData} = useSelector((state: RootState) => state.availabilityData).yesterdayData;

    useEffect(() => {
        if (selectedAssetId) {
            dispatch(
                fetchYesterdayKpiData(
                    `wind-daily-kpi?start_date=${getYesterday()[0]}&end_date=${getYesterday()[1]}&asset_ids=${selectedAssetId}&aggregation=true&level=asset`
                )
            )
            dispatch(
                fetchLast7DaysKpiData(
                    `wind-daily-kpi?start_date=${getLast15Days()[0]}&end_date=${getLast15Days()[1]}&asset_ids=${selectedAssetId}&aggregation=true&level=asset`
                )
            )
            dispatch(fetchYesterdayDAData({aggregation: true, level: "asset", asset_ids: selectedAssetId}))
            dispatch(fetchLast7DaysDAData({aggregation: true, level: "asset", asset_ids: selectedAssetId}))
        }
    }, [selectedAssetId]);

    useEffect(() => {
        if (!last7DaysLoading && last7DaysData) {
            setLast7DaysKPIs(last7DaysData);
        }
    }, [last7DaysLoading, last7DaysData]);

    useEffect(() => {
        if (!yesterdayLoading && yesterdayData) {
            setYesterdayKPIs(yesterdayData);
        }
    }, [yesterdayData, yesterdayLoading]);

    return (
        <>
            <Grid size={2}>
                <Card>
                    <KpiWidget last7DaysColor={'red'}
                               loading={last7DaysLoading}
                               unit={'GWh'}
                               title={'Actual Generation'}
                               yesterdayValue={convertToGWh(yesterdayKPIs?.total_active_power)}
                               last7DaysValue={convertToGWh(last7DaysKPIs?.total_active_power)}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'GWh'}
                               title={'Potential Generation'}
                               loading={last7DaysLoading}
                               yesterdayValue={convertToGWh(yesterdayKPIs?.total_estimated_power)}
                               last7DaysValue={convertToGWh(last7DaysKPIs?.total_estimated_power)}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'GWh'} title={'Lost Power'}
                               loading={last7DaysLoading}
                               yesterdayValue={convertToGWh(yesterdayKPIs?.total_lost_power)}
                               last7DaysValue={convertToGWh(last7DaysKPIs?.total_lost_power)}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'m/s'} title={'Avg Wind Speed'}
                               loading={last7DaysLoading}
                               yesterdayValue={yesterdayKPIs?.avg_wind_speed?.toFixed(2)}
                               last7DaysValue={last7DaysKPIs?.avg_wind_speed?.toFixed(2)}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'%'} title={'Capacity Factor'}
                               loading={last7DaysLoading}
                               yesterdayValue={calculateCF(yesterdayKPIs?.total_cf_numerator, yesterdayKPIs?.total_cf_denominator)}
                               last7DaysValue={calculateCF(last7DaysKPIs?.total_cf_numerator, last7DaysKPIs?.total_cf_denominator)}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'%'} title={'Time Based Availability'}
                               loading={last7DaysLoading}
                               yesterdayValue={calculateAvailabilities(yesterdayKPIs?.total_tba_numerator, yesterdayKPIs?.total_tba_denominator)}
                               last7DaysValue={calculateAvailabilities(last7DaysKPIs?.total_tba_numerator, last7DaysKPIs?.total_tba_denominator)}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'%'} title={'Energy Based Availability'}
                               loading={last7DaysLoading}
                               yesterdayValue={calculateAvailabilities(yesterdayKPIs?.total_eba_numerator, yesterdayKPIs?.total_eba_denominator)}
                               last7DaysValue={calculateAvailabilities(last7DaysKPIs?.total_eba_numerator, last7DaysKPIs?.total_eba_denominator)}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'$'} title={'Actual Revenue'}
                               loading={last7DaysLoading}
                               yesterdayValue={formatNumber(yesterdayKPIs?.total_actual_revenue)}
                               last7DaysValue={formatNumber(last7DaysKPIs?.total_actual_revenue)}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'$'} title={'Potential Revenue'}
                               loading={last7DaysLoading}
                               yesterdayValue={formatNumber(yesterdayKPIs?.total_potential_revenue)}
                               last7DaysValue={formatNumber(last7DaysKPIs?.total_potential_revenue)}></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget unit={'%'} title={'Data Availability'}
                               loading={last7DaysLoading}
                               yesterdayValue={yesterdayDAData?.total_percentage?.toFixed(2)}
                               last7DaysValue={last7DaysDAData?.total_percentage?.toFixed(2)}></KpiWidget>
                </Card>
            </Grid>

            {/*<Grid size={12}>*/}
            {/*    <Card>*/}
            {/*        <CardContent>*/}
            {/*            <AGDataTable rowData={dailyKPIData} colDefs={kpiColumns} defaultColDef={[]}*/}
            {/*                         title={'Daily KPIs - Last 30 days'}></AGDataTable>*/}
            {/*        </CardContent>*/}
            {/*    </Card>*/}
            {/*</Grid>*/}
            {/*<Grid size={12}>*/}
            {/*    <Card>*/}
            {/*        <CardContent>*/}
            {/*            <AGDataTable rowData={monthlyKPIData} colDefs={kpiColumns} defaultColDef={[]}*/}
            {/*                         title={'Monthly KPIs - Last 12 months'}></AGDataTable>*/}
            {/*        </CardContent>*/}
            {/*    </Card>*/}
            {/*</Grid>*/}
        </>
    )
}

export default KPITab