import * as Highcharts from "highcharts";

const dataAnalysisHeatMapChartOptions: any = {
    chart: {
        type: 'heatmap',
        height: 700,
    },
    title: {
        text: '',
    },
    boost: {
        useGPUTranslations: true,
        usePreAllocated: true
    },
    xAxis: {
        categories: []
    },

    yAxis: {
        categories: [],
        title: null,
        min: 0,
        startOnTick: false,
        endOnTick: false,
        reversed: false,
        scrollbar: {
            enabled: true
        },
        max: 30,
        gridLineWidth: 0.5,  // Reduce grid line thickness for better performance
        tickLength: 0  // Optional: Hide ticks for cleaner appearance
    },

    accessibility: {
        point: {
            descriptionFormat: '{(add index 1)}. ' +
                '{series.xAxis.categories.(x)} sales ' +
                '{series.yAxis.categories.(y)}, {value}.'
        }
    },

    colorAxis: {
        min: 0,
        max: 144,
        minColor: 'red',
        maxColor: 'green',
        stops: [
            [0, 'red'],            // 0% (value 0) -> Red
            [0.1, '#ff4000'],      // 10% (value 14.4) -> Dark Orange/Red
            [0.2, 'orange'],       // 20% (value 28.8) -> Orange
            [0.3, '#ffbf00'],      // 30% (value 43.2) -> Orange-Yellow
            [0.4, 'yellow'],       // 40% (value 57.6) -> Yellow
            [0.5, '#bfff00'],      // 50% (value 72) -> Yellow-Green
            [0.6, 'lightgreen'],   // 60% (value 86.4) -> Light Green
            [0.7, '#80ff00'],      // 70% (value 100.8) -> Bright Green
            [0.8, 'green'],        // 80% (value 115.2) -> Green
            [0.9, '#008000'],      // 90% (value 129.6) -> Dark Green
            [1, 'darkgreen'],      // 100% (value 144) -> Darkest Green
        ],
    },

    legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 0,
        symbolHeight: 280
    },

    tooltip: {
        shared: true,  // Show data for all series in the tooltip when hovered
        split: false,  // If true, tooltips are split into boxes for each series
        xDateFormat: '%Y-%m-%d %H:%M',  // Format for the x-axis (date)
        valueDecimals: 2,  // Number of decimal places for the y-axis values
        useHTML: true,  // Enable HTML in the tooltip
        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Semi-transparent black background
        borderColor: '#FF5733', // Orange border color
        borderRadius: 10, // Rounded corners
        style: {
            color: '#FFFFFF',  // White text
            fontSize: '14px',  // Slightly larger text
        },
        format: '<b>{series.xAxis.categories.(point.x)}</b> has<br>' +
            '<b>{point.value}</b> data points on <br>' +
            '<b>{series.yAxis.categories.(point.y)}</b>'
    },

    series: [{
        borderWidth: 1,
        data: [],
        dataLabels: {
            enabled: true,
            color: '#000000'
        }
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                yAxis: {
                    labels: {
                        format: '{substr value 0 1}'
                    }
                }
            }
        }]
    }
};

export default dataAnalysisHeatMapChartOptions;