import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import MonitorIcon from "@mui/icons-material/Monitor";
import {Insights, Scale} from "@mui/icons-material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {ElementType} from "react";

// Define a TypeScript type for the menu items
type MenuItem = {
    title: string;
    url: string;
    id: string;
    icon: ElementType; // ReactNode is used for JSX elements like icons
};

export const solarLeftHandSideMenu: MenuItem[] = [
    {
        title: 'Overview',
        url: '/solar/overview',
        id: 'solar_overview',
        icon: RemoveRedEyeOutlinedIcon
    },
    {
        title: 'Monitor',
        id: 'solar_monitor',
        url: '/solar/monitoring',
        icon: MonitorIcon
    },
    {
        title: 'Analysis',
        url: '/solar/analysis',
        id: 'solar_analysis',
        icon: Insights
    },
    {
        title: 'Performance',
        url: '/solar/performance',
        id: 'solar_performance',
        icon: Scale
    },
    {
        title: 'Reports',
        url: '/solar/reports',
        id: 'solar_reports',
        icon: ArticleOutlinedIcon
    }
]