import React, {useEffect, useState} from 'react';
import {LayersControl, MapContainer, Marker, Popup, TileLayer, useMap, ZoomControl} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import calculateCenter from "../utils/mapCentre";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {LocationOn, Room} from '@mui/icons-material'; // Import the MUI Icon
import { renderToString } from 'react-dom/server';
import {MapData} from "../interfaces/map";
import Grid from "@mui/material/Grid2"; // Import renderToString to convert JSX to string

// Helper function to create a Leaflet divIcon with a MUI Icon inside
const createMuiMarkerIcon = () => {
    const iconHtml = renderToString(<LocationOn style={{ color: 'red', fontSize: '40px' }} />);

    return L.divIcon({
        className: 'custom-div-icon',  // You can style it via CSS
        html: iconHtml,                // Use the MUI Icon inside the marker
        iconSize: [40, 42],            // Size of the marker icon
        iconAnchor: [15, 42],          // Anchor of the marker (middle bottom)
        popupAnchor: [0, -40],         // Anchor of the popup (relative to the marker)
    });
};

interface OpenWeatherMapProps {
    coords: any[],
    defaultZoom: number,
    minHeight?: string
}

const API_KEY = '6eb815ece846bb6bdcc19ab90271cf8a'
// OpenWeatherMap layers
const cloudsLayer = `https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=${API_KEY}`;
const precipitationLayer = `https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=${API_KEY}`;
const windLayer = `https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=${API_KEY}`;
const tempLayer = `https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=${API_KEY}`;
const pressureLayer = `https://tile.openweathermap.org/map/pressure_new/{z}/{x}/{y}.png?appid=${API_KEY}`;

const OpenWeatherMap: React.FC<OpenWeatherMapProps> = ({coords, defaultZoom = 5, minHeight = '50vh'}) => {
    const [mapCentre, setMapCentre] = useState({lat: 38.3774, lng: -121.4444})
    const muiIcon = createMuiMarkerIcon();
    useEffect(() => {
        if (coords.length > 0) {
            setMapCentre(calculateCenter(coords))
        }
    }, [coords]);

    // @ts-ignore
    const ChangeView = ({ center, zoom }) => {
        const map = useMap();
        map.setView(center, zoom);
        return null;
    }

    return (
        <Grid size={12}>
            <MapContainer
                style={{height: minHeight, width: '100%'}}
                center={mapCentre} // Center the map on the provided latitude and longitude
                zoom={defaultZoom} // Set zoom level, default is 5
            >
                <ZoomControl position="bottomright" />
                {coords.map((item: MapData, index) => (
                    <Marker position={{lat: item.latitude, lng: item.longitude}} icon={muiIcon}>
                        <Popup>
                            A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                    </Marker>
                ))}
                <ChangeView center={mapCentre} zoom={defaultZoom} />
                <LayersControl position="topright">
                    {/* Base Layer (Always Available) */}
                    <LayersControl.BaseLayer name="OpenStreetMap">
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                    </LayersControl.BaseLayer>

                    <LayersControl.BaseLayer checked name="Terrain map">
                        <TileLayer
                            url="http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}"
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            attribution='&copy; Google Maps'
                        />
                    </LayersControl.BaseLayer>

                    <LayersControl.BaseLayer name="Google Satellite">
                        <TileLayer
                            url="http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            attribution='&copy; Google Maps'
                        />
                    </LayersControl.BaseLayer>

                    {/* Weather Overlays from OpenWeatherMap */}
                    <LayersControl.Overlay name="Clouds">
                        <TileLayer url={cloudsLayer} attribution='&copy; <a href="https://openweathermap.org/">OpenWeatherMap</a>' />
                    </LayersControl.Overlay>

                    <LayersControl.Overlay name="Precipitation">
                        <TileLayer url={precipitationLayer} attribution='&copy; <a href="https://openweathermap.org/">OpenWeatherMap</a>' />
                    </LayersControl.Overlay>

                    <LayersControl.Overlay checked name="Wind">
                        <TileLayer url={windLayer} attribution='&copy; <a href="https://openweathermap.org/">OpenWeatherMap</a>' />
                    </LayersControl.Overlay>

                    <LayersControl.Overlay name="Temperature">
                        <TileLayer url={tempLayer} attribution='&copy; <a href="https://openweathermap.org/">OpenWeatherMap</a>' />
                    </LayersControl.Overlay>

                    <LayersControl.Overlay name="Pressure">
                        <TileLayer url={pressureLayer} attribution='&copy; <a href="https://openweathermap.org/">OpenWeatherMap</a>' />
                    </LayersControl.Overlay>
                </LayersControl>

            </MapContainer>
        </Grid>
    );
};

export default OpenWeatherMap;