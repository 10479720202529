import React, {useState} from "react";
import Grid from "@mui/material/Grid2";
import TabComponent from "../../../components/Tabs";
import PageTileAndBreadCrumb from "../../../components/PageTileAndBreadCrumb";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../slices";
import {MapData} from "../../../interfaces/map";
import OpenWeatherMap from "../../../components/WhetherMap";
import ProtectedComponent from "../../../components/ProtectedComponent";
import DevicesTab from "./DevicesTab";
import {NoAssetSelected} from "../../../components/NoAssetSelected";
import {tabList} from "../../../data/tabs/wind_monitor";
import KPITab from "./KPITab";
import LiveMonitoringTab from "./LiveMonitoringTab";

const WindMonitoring: React.FC = () => {
    const {selectedAssetId} = useSelector((state: RootState) => state.global);
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [assetMapData, setAssetsMapData] = useState<MapData[]>([])

    const handleTabChange = (newValue: number) => {
        setSelectedTab(newValue); // Update the state with the selected tab
    };

    const required_module_id = 'wind_monitoring'

    const whenAssetSelected = (
        <ProtectedComponent requiredPermission={required_module_id}>
            <TabComponent selectedTabNumber={0} onTabChange={handleTabChange} tabList={tabList}/>
            {selectedTab === 0 && <KPITab />}
            {selectedTab === 1 && <LiveMonitoringTab />}
            {selectedTab === 5 && <DevicesTab />}
            {selectedTab === 4 && <OpenWeatherMap minHeight={'75vh'} coords={assetMapData} defaultZoom={12}></OpenWeatherMap>}
        </ProtectedComponent>
    )

    return (
        <Grid container spacing={2}>
            <PageTileAndBreadCrumb showAssetDropDown={true} title={'Asset Monitoring'}></PageTileAndBreadCrumb>
            {selectedAssetId && whenAssetSelected}
            {(selectedAssetId === undefined || selectedAssetId === null) && <NoAssetSelected />}
        </Grid>
    )
}

export default WindMonitoring;