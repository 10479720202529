// slices/kpiSlice.ts
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from "../utils/interceptor";

interface RawData {
    date: string;
    device_id: string;
    device_name: string;
    time_stamp_utc: string;
    time_stamp_asset: string;
    wind_speed: number;
    active_power: number;
    lost_power: number;
    estimated_power: number;
    timezone: string;
    time_available: number;
    id: number;
}

// Define the initial state type
interface DataState {
    rawData: any[]
    loading: boolean
    error: string | null
}

const initialState: DataState = {
    rawData: [],
    loading: false,
    error: null,
};

// Thunk to fetch data from an API
export const fetchRawData = createAsyncThunk('user/fetchRawData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return await response.data;
});


const dataAnalysisSlice = createSlice({
    name: 'kpi',
    initialState,
    reducers: {
        resetRawDataState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRawData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRawData.fulfilled, (state, action) => {
                state.loading = false;
                state.rawData = action.payload;
            })
            .addCase(fetchRawData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch data';
            })
    },
});

export const { resetRawDataState } = dataAnalysisSlice.actions;

export default dataAnalysisSlice.reducer;
