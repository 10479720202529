import React, {useEffect, useState} from "react";
import {AdvancedMarker, APIProvider, InfoWindow, Map} from "@vis.gl/react-google-maps";
import {MapData} from "../interfaces/map";
import calculateCenter from "../utils/mapCentre";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store";
import {fetchWeatherData} from "../slices/weatherSlice";

interface ChildProps {
    coords: any[],
    defaultZoom: number,
    infoWindowHeaderContent?: string
    showInfoWindow?: boolean
}

const API_KEY = 'AIzaSyDei-YuBcEuqgx919Z5j1-Vh7V3Lz1ZqBE'

const GoogleMap: React.FC<ChildProps> = ({coords, defaultZoom = 3, infoWindowHeaderContent, showInfoWindow}) => {
    const [mapCentre, setMapCentre] = useState({lat: 0, lng: 0})
    const [selectedMarkerIndex, setSelectedMarkerIndex] = useState(null);
    const {loading, error, weatherData} = useSelector((state: RootState) => state.weatherData);
    const [weatherInfo, setWeatherInfo] = useState<any>();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (coords.length > 0) {
            setMapCentre(calculateCenter(coords))
        }
    }, [coords]);

    useEffect(() => {
        if (!loading && weatherData) {
            setWeatherInfo(weatherData)
        }
    }, [loading, weatherData]);

    const handleMarkerClick = (item: any, index: any) => {
        dispatch(fetchWeatherData(`weather?lat=${item.latitude}&lon=${item.longitude}`))
        setSelectedMarkerIndex(index);  // Set the selected marker to display InfoWindow
    };

    return (
        <APIProvider apiKey={API_KEY}>
            <Map center={{lat: mapCentre.lat, lng: mapCentre.lng}} defaultZoom={defaultZoom} mapId='DEMO_MAP_ID'>
                {coords.map((item: MapData, index) => (
                    <>
                        <AdvancedMarker position={{lat: item.latitude, lng: item.longitude}}
                                        onClick={() => handleMarkerClick(item, index)}
                                        key={index}>
                        </AdvancedMarker>
                        {(showInfoWindow && selectedMarkerIndex === index) && (
                            <InfoWindow headerContent={infoWindowHeaderContent}
                                        position={{ lat: item.latitude + 0.5, lng: item.longitude }}
                                        onCloseClick={() => setSelectedMarkerIndex(null)}>
                                <div className="infowindow">
                                    <h4>{item.name}</h4>
                                    <p><strong>Wind Speed:</strong> {weatherInfo?.wind?.speed} m/s</p>
                                    <p><strong>Wind Direction:</strong> {weatherInfo?.wind?.deg} deg</p>
                                    <p><strong>Temperature:</strong> {weatherInfo?.main?.temp} deg</p>
                                </div>
                            </InfoWindow>
                        )}
                    </>
                ))}
            </Map>
        </APIProvider>
    )
}

export default GoogleMap;