import React, {useEffect, useMemo, useRef, useState} from "react";
import Grid from "@mui/material/Grid2";
import FilterFormComponent from "../../../components/Filters";
import {Card, CardContent} from "@mui/material";
import {Info} from "@mui/icons-material";
import {HighchartsReact} from "highcharts-react-official";
import HighchartsStock from "highcharts/highstock";
import dataAnalysisChartOptions from "../../../data/chartOptions/data_analysis_chart_options";
import AGDataTable from "../../../components/AGDataTable";
import {ColDef} from "ag-grid-enterprise";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../store";
import {fetchRawData, resetRawDataState} from "../../../slices/analysisSlice";
import {Alert} from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import Heatmap from "highcharts/modules/heatmap";
import Highcharts from "highcharts";
import {epochConversion} from "../../../utils/dateUtils";
import {defaultCols} from "../../../utils/ag-table";
import groupBy from "lodash/groupBy";
import {resetFilters} from "../../../slices/filterSlice";
Heatmap(Highcharts);

const RawAnalysis: React.FC = () => {
    const {filterData, filtersLoading, filtersError} = useSelector((state: RootState) => state.filterData)
    const {rawData, loading, error} = useSelector((state: RootState) => state.rawData)
    const [tableData, setTableData] = useState<any[]>([])
    const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false)
    const [chartOptions, setChartOptions] = useState<any>(dataAnalysisChartOptions)
    const [showInitialMessage, setShowInitialMessage] = useState<boolean>(true)
    const dataAnalysisChartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [userMessage, setUserMessage] = useState<{ message: string | null, alertSeverity: any }>({
        message: "",
        alertSeverity: ""
    });
    const dispatch = useDispatch<AppDispatch>()
    let windDataAnalysisChart = dataAnalysisChartComponentRef.current?.chart;

    const columns: ColDef[] = [
        {field: 'id', headerName: 'User ID', maxWidth: 200, hide: true},
        {field: 'device_id', headerName: 'Device Name'},
        {field: 'time_stamp_asset', headerName: 'Time Stamp', flex: 1,},
        {
            field: 'wind_speed', headerName: 'Wind Speed', flex: 1, valueFormatter: (params) => {
                return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
            }
        },
        {
            field: 'active_power', headerName: 'Active Power', flex: 1, valueFormatter: (params) => {
                return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
            }
        },
        {
            field: 'lost_power', headerName: 'Lost Power', flex: 1, valueFormatter: (params) => {
                return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
            }
        },
        {
            field: 'estimated_power', headerName: 'Estimated Power', flex: 1, valueFormatter: (params) => {
                return `${params.value?.toFixed(2)}`;  // Format number as currency with 2 decimal places
            }
        },
        {field: 'timezone', headerName: 'Timezone', flex: 1},
        {field: 'time_available', headerName: 'Availability', flex: 1},
    ];

    const defaultColDef = useMemo(() => defaultCols, []);

    useEffect(() => {
        if (filterData && filterData.devices.length > 0) {
            setShowInitialMessage(false);
            setIsDataAvailable(true);
            windDataAnalysisChart?.showLoading()

            let signals = filterData.signals;
            let uniqueUnits = new Set(signals.map(signal => signal.unit))
            const unitsArray = Array.from(uniqueUnits);

            clearSeries();

            unitsArray.forEach((unit, index) => {
                const yAxis: any = {
                    title: {
                        text: unit
                    },
                    opposite: false
                }

                if (index % 2 !== 0) {
                    yAxis.opposite = true
                }

                setChartOptions((prevOptions: any) => ({
                    ...prevOptions,
                    yAxis: [...prevOptions.yAxis, yAxis], // Add new series
                }));
            })

            dispatch(
                fetchRawData(
                    `wind-raw-data?start_date=${filterData.timeFrame.startDate?.format('YYYY-MM-DD')}&end_date=${filterData.timeFrame.endDate?.format('YYYY-MM-DD')}&device_ids=${filterData.devices.map(item => item.id)}&signals=${filterData.signals.map(item => item.internalName)}`
                )
            )
        } else {
            setShowInitialMessage(true);
            setIsDataAvailable(false);
        }
    }, [filterData]);

    // Function to clear all series
    const clearSeries = () => {
        setChartOptions((prevOptions: any) => ({
            ...prevOptions,
            yAxis: [],
            series: [], // Clear the series
        }));
    };

    useEffect(() => {
        if (rawData?.length > 0) {
            setTableData(rawData)
            let groupedByDeviceData = groupBy(rawData, 'device_name')
            let signals = filterData.signals;

            for (let device_id in groupedByDeviceData) {

                let deviceData = groupedByDeviceData[device_id];

                signals.forEach(signal => {
                    let deviceChartSeries: any[] = []
                    let signalName: string = signal.internalName
                    let yAxisName = signal.unit
                    deviceData.forEach((dataPoint) => deviceChartSeries.push(
                        [epochConversion(dataPoint.time_stamp_asset), dataPoint[signalName]]
                    ))

                    let yAxisIndex = chartOptions.yAxis.findIndex((axis: any) => axis.title.text === yAxisName)

                    const newSeries = {
                        name: deviceData[0].device_name + '-' + signal.displayLabel,
                        data: deviceChartSeries,
                        yAxis: yAxisIndex
                    };

                    setChartOptions((prevOptions: any) => ({
                        ...prevOptions,
                        series: [...prevOptions.series, newSeries], // Add new series
                    }));
                })
            }

            windDataAnalysisChart?.hideLoading()
            windDataAnalysisChart?.redraw()
        }
    }, [rawData]);

    useEffect(() => {
        if (error) {
            setUserMessage({message: error, alertSeverity: "error"});
            setOpenSnackBar(true);
        }
    }, [error]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters())
            dispatch(resetRawDataState())
        }
    }, [dispatch]);

    return (
        <>
            <Grid size={12}>
                <FilterFormComponent
                    showAssets={true}
                    showDevices={true}
                    showSignals={true}
                    showTimeFrame={true} />
            </Grid>

            <Grid size={12}>
                {
                    showInitialMessage &&
                    <Card>
                        <CardContent sx={{paddingBottom: '16px !important'}}>
                            <div className={'flex justify-center align-items-center items-center'}>
                                <div className={'flex flex-col justify-center align-items-center items-center'}>
                                    <Info fontSize={'small'}></Info>
                                    <span>Please select filters and click submit</span>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                }
                {
                    isDataAvailable &&
                    <>
                        <Card className={'mb-4'}>
                            <CardContent>
                                <HighchartsReact
                                    constructorType={'stockChart'}
                                    highcharts={HighchartsStock}
                                    ref={dataAnalysisChartComponentRef}
                                    options={chartOptions}>
                                </HighchartsReact>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <AGDataTable
                                    rowData={tableData}
                                    colDefs={columns}
                                    defaultColDef={defaultColDef}
                                    title={''}
                                />
                            </CardContent>
                        </Card>
                    </>
                }
            </Grid>

            <Snackbar open={openSnackBar}
                      autoHideDuration={6000}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                      onClose={() => setOpenSnackBar(false)}>
                <Alert
                    onClose={() => setOpenSnackBar(false)}
                    severity={userMessage.alertSeverity}
                    variant="filled"
                    sx={{width: '100%'}}>
                    {userMessage.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default RawAnalysis