import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../../store";
import Grid from "@mui/material/Grid2";
import {
    Card,
    CardContent,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import WindPowerIcon from '@mui/icons-material/WindPower';
import {
    Headphones,
    Info,
} from "@mui/icons-material";
import TabComponent from "../../../components/Tabs";
import PageTileAndBreadCrumb from "../../../components/PageTileAndBreadCrumb";
import {RootState} from "../../../slices";
import {resetFilters} from "../../../slices/filterSlice";
import ProtectedComponent from "../../../components/ProtectedComponent";
import {wind_performance_tabs} from "../../../data/tabs/wind_performance";
import WindPCTab from "../Performance/WindPCTab";
import WindLossAnalysisTab from "../Performance/WindLossAnalysisTab";
import WindAlarmsTab from "../Performance/WindAlarmsTab";
import {NoAssetSelected} from "../../../components/NoAssetSelected";

const WindReports: React.FC = () => {
    const {selectedAssetId} = useSelector((state: RootState) => state.global);
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const dispatch = useDispatch<AppDispatch>()
    const handleTabChange = (newValue: number) => {
        dispatch(resetFilters())
        setSelectedTab(newValue); // Update the state with the selected tab
    };

    const required_module_id = 'wind_performance'

    const whenAssetSelected = (
        <ProtectedComponent requiredPermission={required_module_id}>
            <TabComponent selectedTabNumber={0} onTabChange={handleTabChange} tabList={wind_performance_tabs}/>
        </ProtectedComponent>
    )

    return (
        <Grid container spacing={2}>
            <PageTileAndBreadCrumb showAssetDropDown={true} title={'Reports'}></PageTileAndBreadCrumb>
            {selectedAssetId && whenAssetSelected}
            {(selectedAssetId === undefined || selectedAssetId === null) && <NoAssetSelected />}
        </Grid>
    )
}

export default WindReports;