import * as Highcharts from "highcharts";

const performancePCChartOptions: Highcharts.Options = {
    chart: {
        height: 600,
        type: "spline",
    },
    time: {
        useUTC: false
    },
    xAxis: {
        tickInterval: 0.25
    },
    yAxis: {
        title: {
            text: ''
        }
    },
    boost: {
        useGPUTranslations: true,
        usePreallocated: true
    },
    title: {
        text: '',
    },
    legend: {
        enabled: true, // Enable the legend
        layout: 'horizontal', // You can also set it to 'vertical'
        align: 'center',
        verticalAlign: 'bottom', // Position at the bottom of the chart
    },
    tooltip: {
        shared: true,  // Show data for all series in the tooltip when hovered
        split: false,  // If true, tooltips are split into boxes for each series
        useHTML: true,  // Enable HTML in the tooltip
        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Semi-transparent black background
        borderColor: '#FF5733', // Orange border color
        borderRadius: 10, // Rounded corners
        style: {
            color: '#FFFFFF',  // White text
            fontSize: '14px',  // Slightly larger text
        },
        formatter: function () {
            // Convert epoch (x value) to a human-readable date
            const xValue = this.point.x ?? '0.00';
            let tooltipHtml = `<div style="text-align: left;"><b>Wind Speed (m/s)</b>: ${xValue}<br/>`;
            this.points?.forEach(point => {
                // Safely access point.y, use 0 as fallback if null or undefined
                const yValue = point.y?.toFixed(2) ?? '0.00';
                tooltipHtml += `<span style="color: ${point?.series?.color};">● </span><b>${point?.series?.name}</b>: ${yValue}<br/>`;
            });
            tooltipHtml += `</div>`;
            return tooltipHtml;
        }
    },
    series: []
};

export default performancePCChartOptions;