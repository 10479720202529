// slices/dataAvailability.ts
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from "../utils/interceptor";
import {DataAvailability} from "../interfaces/dataAvailability";
import {getLast7Days, getYesterday} from "../utils/dateUtils";

// Define the initial state type
interface DataState {
    dataAvailabilityData: DataAvailability[],
    last7DaysData: { aggregatedData: any,  loading: boolean, error: string | null },
    yesterdayData: { aggregatedData: any,  loading: boolean, error: string | null },
    dataAvailabilityLoading: boolean
    dataAvailabilityError: string | null
}

const initialState: DataState = {
    dataAvailabilityData: [],
    dataAvailabilityLoading: false,
    dataAvailabilityError: null,
    last7DaysData: {
        aggregatedData: {},
        loading: false,
        error: null,
    },
    yesterdayData: {
        aggregatedData: {},
        loading: false,
        error: null,
    },
};

// Thunk to fetch data from an API
export const fetchDataAvailabilityData = createAsyncThunk('user/fetchDataAvailabilityData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return await response.data;
});

export const fetchLast7DaysDAData = createAsyncThunk('user/fetchLast7DaysDAData', async (config: any) => {
    const start_date = getLast7Days()[0];
    const end_date = getLast7Days()[1];
    const asset_ids = config.asset_ids
    const aggregation = config.aggregation
    const level = config.level
    const apiURL = `wind-data-availability?start_date=${start_date}&end_date=${end_date}&asset_ids=${asset_ids}&aggregation=${aggregation}&level=${level}`
    const response = await api.get(apiURL);
    return await response.data;
});

export const fetchYesterdayDAData = createAsyncThunk('user/fetchYesterdayDAData', async (config: any) => {
    const start_date = getYesterday()[0];
    const end_date = getYesterday()[1];
    const asset_ids = config.asset_ids
    const aggregation = config.aggregation
    const level = config.level
    const apiURL = `wind-data-availability?start_date=${start_date}&end_date=${end_date}&asset_ids=${asset_ids}&aggregation=${aggregation}&level=${level}`
    const response = await api.get(apiURL);
    return await response.data;
});


const dataAvailabilitySlice = createSlice({
    name: 'dataAvailability',
    initialState,
    reducers: {
        resetDataAvailabilityDataState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDataAvailabilityData.pending, (state) => {
                state.dataAvailabilityLoading = true;
                state.dataAvailabilityError = null;
            })
            .addCase(fetchDataAvailabilityData.fulfilled, (state, action) => {
                state.dataAvailabilityLoading = false;
                state.dataAvailabilityData = action.payload;
            })
            .addCase(fetchDataAvailabilityData.rejected, (state, action) => {
                state.dataAvailabilityLoading = false;
                state.dataAvailabilityError = action.error.message || 'Failed to fetch data';
            })

            .addCase(fetchLast7DaysDAData.pending, (state) => {
                state.last7DaysData.loading = true;
                state.last7DaysData.error = null;
            })
            .addCase(fetchLast7DaysDAData.fulfilled, (state, action) => {
                state.last7DaysData.loading = false;
                state.last7DaysData.aggregatedData = action.payload;
            })
            .addCase(fetchLast7DaysDAData.rejected, (state, action) => {
                state.last7DaysData.loading = false;
                state.last7DaysData.error = action.error.message || 'Failed to fetch data';
            })

            .addCase(fetchYesterdayDAData.pending, (state) => {
                state.yesterdayData.loading = true;
                state.yesterdayData.error = null;
            })
            .addCase(fetchYesterdayDAData.fulfilled, (state, action) => {
                state.yesterdayData.loading = false;
                state.yesterdayData.aggregatedData = action.payload;
            })
            .addCase(fetchYesterdayDAData.rejected, (state, action) => {
                state.yesterdayData.loading = false;
                state.yesterdayData.error = action.error.message || 'Failed to fetch data';
            })
    },
});

export const { resetDataAvailabilityDataState } = dataAvailabilitySlice.actions;

export default dataAvailabilitySlice.reducer;
