import {AgGridReact} from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-enterprise/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"; // Optional Theme applied to the Data Grid
import Typography from "@mui/material/Typography";
import {Delete, SearchRounded} from "@mui/icons-material";
import {ClientSideRowModelModule, ModuleRegistry, GridApi, ColDef} from "ag-grid-enterprise"; // Column definition interface
import {ColumnsToolPanelModule} from "ag-grid-enterprise";
import {FiltersToolPanelModule} from "ag-grid-enterprise";
import {SetFilterModule} from "ag-grid-enterprise";
import {useDispatch, useSelector} from "react-redux";
import {fetchData, deleteRow, updateRow, addRow, resetAssetCrudState} from "../../slices/assetCrudSlice";
import {AppDispatch} from "../../store";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {Alert} from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid2";

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    SetFilterModule,
]);

interface AGTableProps {}


const AssetsTab: React.FC = (props: AGTableProps) => {
    const gridRef = useRef<AgGridReact>(null);
    const gridApiRef = useRef<GridApi | null>(null);
    const dispatch = useDispatch<AppDispatch>()
    const [tableData, setTableData] = useState<AGTableProps[]>([]);
    const [selectedAsset, setSelectedAsset] = useState<any>(null)
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [userMessage, setUserMessage] = useState("");

    // Select rowData and status from the Redux store
    const {rowData, status, error, deleteStatus, updateStatus} = useSelector((state: any) => state.assetCrudData);

    const columns: ColDef[] = [
        {field: 'id', headerName: 'Asset ID', maxWidth: 200},
        {field: 'name', headerName: 'Asset Name', flex: 1,},
        {field: 'state', headerName: 'State', flex: 1},
        {field: 'currency', headerName: 'Currency', flex: 1},
        {field: 'ppa', headerName: 'PPA', flex: 1},
        {field: 'altitude', headerName: 'Altitude', flex: 1},
        {field: 'total_devices', headerName: 'Total Devices', flex: 1},
        {field: 'asset_timezone', headerName: 'Asset Timezone', flex: 1},
        {field: 'subscribed', headerName: 'Subscribed'},
        {field: 'latitude', headerName: 'Latitude', flex: 1},
        {field: 'longitude', headerName: 'Longitude', flex: 1},
    ];

    const [columnDefs, setColumnDefs] = useState(columns)

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            editable: true,
            filter: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
        };
    }, []);

    // Fetch initial data when component mounts

    useEffect(() => {
        dispatch(fetchData());
        return () => {
            dispatch(resetAssetCrudState());
        }
    }, []);

    useEffect(() => {
        if (deleteStatus === 'succeeded') {
            setUserMessage("Asset Deleted Successfully");
            setOpenSnackBar(true);
        }

        if (updateStatus === 'succeeded') {
            setUserMessage("Asset Updated Successfully");
            setOpenSnackBar(true);
        }
    }, [deleteStatus, updateStatus]);

    useEffect(() => {
        if (rowData.length > 0) {
            setTableData(JSON.parse(JSON.stringify(rowData)));
        }
    }, [rowData]);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const onGridReady = (params: any) => {
        gridApiRef.current = params.api;
        params.api.closeToolPanel();
        addDeleteColumnDynamically();
    };

    /*-- Function to delete a row --*/
    const handleDeleteRow = (params: any) => {
        setSelectedAsset(params.data);
        setOpenDeleteModal(true);
    };

    const addDeleteColumnDynamically = () => {
        const deleteColumn =
                {
                    headerName: 'Actions',
                    cellRenderer: (params: any) => (
                        <button onClick={() => handleDeleteRow(params)}>
                            <Delete />
                        </button>
                    ),
                }

            setColumnDefs((prevColumnDefs: any) => [...prevColumnDefs, deleteColumn]);
    };

    const handleDeleteClose = () => {
        setOpenDeleteModal(false);
    };

    const handleOk = () => {
        dispatch(deleteRow(selectedAsset.id))
        handleDeleteClose();
    };
    /*-- Function to delete a row --*/

    // Handle cell value changes for updates
    const onCellValueChanged = (params: any) => {
        const updatedRow = params.data;
        dispatch(updateRow(updatedRow));
    };

    const handleAddClose = () => {
        setOpenAddModal(false);
    };

    const handleOpenAddModal = () => {
        setOpenAddModal(true);
    }

    const handleCloseSnakeBar = () => {
        setOpenSnackBar(false);
    }

    return (
        // wrapping container with theme & size
        <div style={{width: "100%", height: "700px", display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: "16px"}}>
                <div className={'title'}>
                    <Typography variant="h6" component="div">
                        Assets Information
                    </Typography>
                </div>
                <div className={'flex flex-row'}>
                    <Button variant={'contained'} onClick={handleOpenAddModal}>Add New Asset</Button>
                    <div className="relative ml-4">
                        <input
                            type="text"
                            className="border border-gray-300 py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Search..."
                            id='filter-text-box'
                            onChange={onFilterTextBoxChanged}
                        />
                        <span className="w-5 h-5 absolute left-3 transform -translate-y-1/2 text-gray-500"
                              style={{top: "45%"}}>
                        <SearchRounded></SearchRounded>
                    </span>
                    </div>
                </div>
            </div>

            <div style={{height: "100%", width: "100%"}} className={"ag-theme-quartz"}>
                <AgGridReact
                    ref={gridRef}
                    onGridReady={onGridReady}
                    rowData={tableData}
                    pagination={true}
                    onCellValueChanged={onCellValueChanged}
                    rowSelection="single"
                    animateRows={true}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    sideBar={true}
                />
            </div>

            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={openDeleteModal}>
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent dividers>
                    <p>Are you sure you want to delete <strong>{selectedAsset?.name}</strong> asset?</p>
                    <br />
                    <p>Note: All the dependent devices and subscriptions will also be deleted</p>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDeleteClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleOk}>Ok</Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={openSnackBar}
                      autoHideDuration={6000}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      onClose={handleCloseSnakeBar}>
                <Alert
                    onClose={handleCloseSnakeBar}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {userMessage}
                </Alert>
            </Snackbar>

            <Dialog
                open={openAddModal}
                fullWidth={true}
                onClose={handleAddClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        dispatch(addRow(formJson));
                        handleAddClose();
                    },
                }}>
                <DialogTitle>Add New Asset</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To add new asset please fill up the following details.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="name"
                                name="name"
                                label="Asset Name"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="latitude"
                                name="latitude"
                                label="Latitude"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="longitude"
                                name="longitude"
                                label="Longitude"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="capacity"
                                name="capacity"
                                label="Capacity"
                                type="number"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="state"
                                name="state"
                                label="State"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="country"
                                name="country"
                                label="Country"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="total_devices"
                                name="total_devices"
                                label="Total Devices"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="currency"
                                name="currency"
                                label="Currency"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="ppa"
                                name="ppa"
                                label="PPA"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="altitude"
                                name="altitude"
                                label="Altitude"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="asset_timezone"
                                name="asset_timezone"
                                label="Asset Timezone"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="subscribed"
                                name="subscribed"
                                label="Subscribed"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="asset_type"
                                name="asset_type_id"
                                label="Asset Type"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="customer_id"
                                name="customer_id"
                                label="Customer ID"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddClose}>Cancel</Button>
                    <Button variant={'contained'} type="submit">Save Asset</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AssetsTab;