import React, {useEffect, useMemo, useState} from "react";
import Grid from "@mui/material/Grid2";
import {Card, CardContent} from "@mui/material";
import AGDataTable from "../../../components/AGDataTable";
import {ColDef} from "ag-grid-enterprise";
import liveData from "../../../data/mock/liveData.json"
import dayjs from "dayjs";

const LiveMonitoringTab:React.FC = () => {
    const [turbineData, setTurbineData] = useState(liveData);

    const liveMonitoringColumns: ColDef[] = [
        {
            field: "Device",
            headerName: "Device",
        },
        {
            field: "activePower",
            headerName: "Active Power",
        },
        {
            field: "windSpeed",
            headerName: "Wind Speed",
        },
        {
            field: "windDirection",
            headerName: "Wind Direction",
        },
        {
            field: "nacelleDirection",
            headerName: "Nacelle Direction",
        },
        {
            field: "status",
            headerName: "Status",
            cellClassRules: {
                // Conditional CSS classes for status column
                'cell-green': params => params.value === 'Operational',
                'cell-red': params => params.value === 'Critical',
                'cell-yellow': params => params.value === 'Under Maintenance'
            }
        },
        {
            field: "alarm",
            headerName: "Alarm",
        },
        {
            field: "lastUpdated",
            headerName: "Last Updated",
        }
    ]

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            editable: true,
            filter: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
        };
    }, []);

    // Simulate live data update (e.g., could be fetching new data via API)
    useEffect(() => {
        const intervalId = setInterval(() => {
            // Example: Simulate updates to wind speed and power generation
            const today = dayjs();
            setTurbineData((prevData) =>
                prevData.map((turbine: any) => ({
                    ...turbine,
                    windSpeed: ((+turbine.windSpeed) + Math.random() * 2 - 1).toFixed(1), // Random wind speed change
                    activePower: Math.max(0, Math.round(turbine.activePower + Math.random() * 20 - 10)), // Random power fluctuation
                    lastUpdated: today.format('YYYY-MM-DD HH:mm:ss'),
                }))
            );
        }, 5000); // Update every 5 seconds

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, []);

    return (
        <>
            <Grid size={12}>
                <Card>
                    <CardContent>
                        <AGDataTable height={650} rowData={turbineData} colDefs={liveMonitoringColumns} defaultColDef={defaultColDef} title={'Live Data & Status'}></AGDataTable>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}

export default LiveMonitoringTab;