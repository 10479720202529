// src/features/globalSlice.js
import { createSlice } from '@reduxjs/toolkit';

interface GlobalState {
    assetType: string;
    selectedLHSMenuId: string;
    selectedAssetId?: number;
}

const initialState: GlobalState = {
    assetType: 'fleet',
    selectedLHSMenuId: '',
    selectedAssetId: undefined,
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setAssetType: (state, action) => {
            state.assetType = action.payload;
        },
        resetAssetType: (state) => {
            state.assetType = 'fleet'
        },
        setSelectedLHSMenuId: (state, action) => {
            state.selectedLHSMenuId = action.payload;
        },
        resetSelectedLHSMenuId: (state) => {
            state.selectedLHSMenuId = ''
        },
        setSelectedAssetId: (state, action) => {
            state.selectedAssetId = action.payload;
        },
        resetSelectedAssetId: (state) => {
            state.selectedAssetId = undefined;
        },
    },
});

export const { setAssetType, resetAssetType, setSelectedLHSMenuId, resetSelectedLHSMenuId, setSelectedAssetId, resetSelectedAssetId } = globalSlice.actions;

export default globalSlice.reducer;