import React, {useEffect, useState} from "react";
import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText, Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import {CSSObject, styled, Theme, useTheme} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../slices";
import {AppDispatch} from "../store";
import {setSelectedLHSMenuId} from "../slices/global";
import {windLeftHandSideMenu} from "../data/menus/windLHSMenu";
import {solarLeftHandSideMenu} from "../data/menus/solarLHSMenu";

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const drawerWidth = 240;

interface SidenavProps {
    isSidebarOpen: boolean;
    setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
}


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                },
            },
            {
                props: ({ open }) => !open,
                style: {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                },
            },
        ],
    }),
);


const SideNavigation : React.FC<SidenavProps> = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [menuItems, setMenuItems] = useState<any[]>([])
    const {assetType, selectedLHSMenuId} = useSelector((state: RootState) => state.global);
    const [selectedMenuItem, setSelectedMenuItem] = useState<any>(null);
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation()

    // Get the current pathname (the URL path)
    const currentUrl = location.pathname;

    const handleDrawerClose = () => {
        setIsSidebarOpen(false);
    }

    useEffect(() => {
        if (assetType === 'wind') {
            if (selectedLHSMenuId === '') dispatch(setSelectedLHSMenuId('wind_overview'));
            setMenuItems(windLeftHandSideMenu);
        }
        else if (assetType === 'solar') {
            if (selectedLHSMenuId === '') dispatch(setSelectedLHSMenuId('solar_overview'));
            setMenuItems(solarLeftHandSideMenu);
        }
        else setMenuItems([]);
    }, [assetType]);

    useEffect(() => {
        setSelectedMenuItem(selectedLHSMenuId);
    }, [selectedLHSMenuId]);

    useEffect(() => {
        if (menuItems.length > 0) {
            const currentPage = menuItems.filter(menuItem => menuItem.url == currentUrl)[0];
            if (currentPage) {
                dispatch(setSelectedLHSMenuId(currentPage.id));
            }
        }
    }, [menuItems]);

    const handleLinkClick = (menu: any) => {
        dispatch(setSelectedLHSMenuId(menu.id));
        navigate(menu.url)
    }

    return (
        <Drawer variant="permanent" open={isSidebarOpen}>
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                {
                    menuItems.map((menu) => (
                        <Tooltip title={menu.title} key={menu.id} placement="right">
                            <ListItem key={menu.id} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    selected={menu.id === selectedMenuItem}
                                    onClick={() => handleLinkClick(menu)}
                                    sx={[{ minHeight: 48, px: 2.5,},
                                        isSidebarOpen ? { justifyContent: 'initial'} : { justifyContent: 'center' },
                                    ]}>
                                    <ListItemIcon
                                        sx={[{minWidth: 0, justifyContent: 'center'},
                                            isSidebarOpen ? {mr: 3} : {mr: 'auto'},
                                        ]}>
                                        <menu.icon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={menu.title}
                                        sx={[isSidebarOpen ? {opacity: 1} : {opacity: 0}]}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                    ))
                }
            </List>
        </Drawer>
    );
}

export default SideNavigation;