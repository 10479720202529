import React, {useState, createContext, ReactNode, useEffect} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../slices";

interface PermissionsContextProps {
    userPermissions: string[];
    loading: boolean;
}

export const PermissionsContext = createContext<PermissionsContextProps>({
    userPermissions: [],
    loading: true,
});

interface PermissionsProviderProps {
    children: ReactNode;
}

export const PermissionsProvider: React.FC<PermissionsProviderProps> = ({ children }) => {
    const [userPermissions, setUserPermissions] = useState<string[]>([]); // Mock permissions
    const {userSubscriptionData, userSubscriptionDataLoading, userSubscriptionError} = useSelector((state: RootState) => state.userData);
    const {selectedAssetId} = useSelector((state: RootState) => state.global);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (userSubscriptionDataLoading) setLoading(true);
    }, [userSubscriptionDataLoading]);

    useEffect(() => {
        if (userSubscriptionData && userSubscriptionData.length > 0 && selectedAssetId) {
            const requiredAssetModules = userSubscriptionData.filter((subAssetData) => subAssetData.asset_id === selectedAssetId)[0]?.modules;
            if (requiredAssetModules && requiredAssetModules.length > 0) {
                const module_ids = requiredAssetModules.map((module: any) => module.module_id);
                setUserPermissions(module_ids);
            }
            setLoading(false)
        }
    }, [userSubscriptionData, selectedAssetId]);

    return (
        <PermissionsContext.Provider value={{ userPermissions, loading }}>
            {children}
        </PermissionsContext.Provider>
    );
};