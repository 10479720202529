import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../slices";
import {AppDispatch} from "../../../store";
import PageTileAndBreadCrumb from "../../../components/PageTileAndBreadCrumb";
import Grid from "@mui/material/Grid2";
import {Card, CardContent} from "@mui/material";
import ProtectedComponent from "../../../components/ProtectedComponent";

const WindFinance: React.FC = () => {
    const required_module_id = 'wind_finance';
    const dispatch = useDispatch<AppDispatch>();
    const {selectedAssetId} = useSelector((state: RootState) => state.global);
    const {userSubscriptionData, userSubscriptionDataLoading, userSubscriptionError} = useSelector((state: RootState) => state.userData);
    const [hasPermission, setHasPermission] = useState<boolean>(false);

    useEffect(() => {
        if (selectedAssetId !== null && userSubscriptionData.length > 0) {
            checkForPermission(userSubscriptionData, required_module_id, selectedAssetId);
        }
    }, [selectedAssetId, userSubscriptionData]);

    const checkForPermission = (subsData: any[], role: string, assetId: number | undefined) => {
        const requiredAssetModules = subsData.filter((subAssetData) => subAssetData.asset_id === assetId)[0].modules;
        const requiredModule = requiredAssetModules.filter((module: any) => module.module_id == role)[0];
        if (requiredModule) {
            setHasPermission(true);
        } else {
            setHasPermission(false);
        }
    }

    return (
        <Grid container spacing={2}>
            <PageTileAndBreadCrumb showAssetDropDown={true} title={'Finance'}></PageTileAndBreadCrumb>
            <ProtectedComponent requiredPermission={required_module_id}>
                <Grid size={12}>
                    <Card>
                        <CardContent>
                            <p>You have access to this module</p>
                        </CardContent>
                    </Card>
                </Grid>
            </ProtectedComponent>
        </Grid>
    )
}

export default WindFinance;