import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from "../utils/interceptor";


// Async thunks for CRUD operations
export const fetchData = createAsyncThunk('data/fetchData', async () => {
    const response = await api.get('assets');
    return response.data;
});

export const addRow = createAsyncThunk('data/addRow', async (newRow: any) => {
    const response = await api.post('assets', newRow);
    return response.data;
});

export const updateRow = createAsyncThunk('data/updateRow', async (updatedRow: any) => {
    await api.put(`asset/${updatedRow.id}`, updatedRow);
    return updatedRow;
});

export const deleteRow = createAsyncThunk('data/deleteRow', async (rowId: number) => {
    await api.delete(`asset/${rowId}`);
    return rowId;
});

// Define the initial state type
interface DataState {
    rowData: any[],
    status: string
    error: string | undefined,
    deleteStatus: string,
    updateStatus: string,
}

const initialState: DataState = {
    rowData: [],
    status: 'idle',
    error: undefined,
    deleteStatus: 'idle',
    updateStatus: 'idle'
};

// Redux slice for managing data
const assetCrudSlice = createSlice({
    name: 'data',
    initialState: initialState,
    reducers: {
        resetAssetCrudState: (state) => initialState,
    },
    extraReducers: (builder) => {
        // Handle fetch data
        builder.addCase(fetchData.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchData.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.rowData = action.payload
        });
        builder.addCase(fetchData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });

        // Handle add row
        builder.addCase(addRow.fulfilled, (state, action) => {
            state.rowData.push(action.payload);
        });

        builder.addCase(updateRow.pending, (state) => {
            state.updateStatus = 'loading'
        });

        // Handle update row
        builder.addCase(updateRow.fulfilled, (state, action) => {
            state.updateStatus = 'succeeded'
            const updatedRow = action.payload;
            const index = state.rowData.findIndex((row) => row.id === updatedRow.id);
            if (index >= 0) {
                state.rowData[index] = updatedRow;
            }
        });

        builder.addCase(deleteRow.pending, (state) => {
            state.deleteStatus = 'loading'
        });

        // Handle delete row
        builder.addCase(deleteRow.fulfilled, (state, action) => {
            state.deleteStatus = 'succeeded'
            const rowId = action.payload;
            state.rowData = state.rowData.filter((row) => {
                return row.id !== rowId
            });
        });
    },
});

export const {resetAssetCrudState} = assetCrudSlice.actions;

export default assetCrudSlice.reducer;