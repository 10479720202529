import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {fetchAssetData, resetAssetState} from "../../slices/assetSlice";
import {Asset, Device} from "../../interfaces/asset";
import Grid from "@mui/material/Grid2";
import {
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import AGDataTable from "../../components/AGDataTable";
import {ColDef} from "ag-grid-enterprise";
import Typography from "@mui/material/Typography";
import GoogleMap from "../../components/Map";
import {MapData} from "../../interfaces/map";
import WindPowerIcon from '@mui/icons-material/WindPower';
import {
    Alarm,
    AutoGraph,
    Compare,
    FlashOn,
    Headphones,
    Info,
    NetworkCheck,
    RemoveRedEye,
    Thunderstorm
} from "@mui/icons-material";
import TabComponent from "../../components/Tabs";

const WindAssetDetails: React.FC = () => {
    const { device_id } = useParams<{ device_id: string }>();
    const navigate = useNavigate();
    const {data, loading, error} = useSelector((state: RootState) => state.assetData)
    const dispatch = useDispatch<AppDispatch>()
    const [assetDetails, setAssetDetails] = useState<Asset>();
    const [assetDevicesData, setAssetDevicesData] = useState<Device[]>([]);
    const [assetMapData, setAssetsMapData] = useState<MapData[]>([])
    const {
        userAssetsData
    } = useSelector((state: RootState) => state.userData);

    useEffect(() => {
        dispatch(fetchAssetData(`asset/${device_id}`))
    }, []);

    useEffect(() => {
        if (!loading && data) {
            setAssetDetails(data)
            const tempAssetData : Device[] = []
            const tempMapData: MapData[] = []
            data.devices.forEach(device => {
                tempAssetData.push({
                    id: device.id,
                    latitude: device.latitude,
                    longitude: device.longitude,
                    name: device.name,
                    status: 'Running',
                    oem: device.oem ? device.oem : 'Vestas',
                    wind_direction: device.wind_direction ? device.wind_direction : 123.56,
                    wind_speed: device.wind_speed ? device.wind_speed : 4.56,
                    nacelle_direction: device.nacelle_direction ? device.nacelle_direction : 120.56,
                    capacity: device.capacity ? device.capacity : 5.25,
                    active_power: device.active_power ? device.active_power : 3378,
                })
                tempMapData.push({latitude: device.latitude, longitude: device.longitude})
            })
            setAssetDevicesData(tempAssetData)
            setAssetsMapData(tempMapData)
        }
    }, [loading, data]);

    const columns: ColDef[] = [
        {field: 'id', headerName: 'Asset ID', maxWidth: 200, hide: true},
        {
            field: 'name', headerName: 'Asset Name', flex: 1,
            cellRenderer: (params: any) => {
                console.log(params)
                return (
                    <button
                        onClick={() => navigate(`/device-details/${params.data.id}`)}>
                        {params.value}
                    </button>
                );
            }
        },
        {field: 'status', headerName: 'Status', flex: 1},
        {field: 'active_power', headerName: 'Active Power (kWh)', flex: 1},
        {field: 'wind_speed', headerName: 'Wind Speed (m/s)', flex: 1},
        {field: 'wind_direction', headerName: 'Wind Direction (*)', flex: 1},
        {field: 'nacelle_direction', headerName: 'Nacelle Direction (*)', flex: 1},
        {field: 'capacity', headerName: 'Capacity (MW)', flex: 1},
        {field: 'oem', headerName: 'OEM', flex: 1},
        {field: 'latitude', headerName: 'Latitude', flex: 1},
        {field: 'longitude', headerName: 'Longitude', flex: 1},
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            editable: true,
            filter: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
        };
    }, []);

    const [selectedTab, setSelectedTab] = useState<number>(0)

    const handleTabChange = (newValue: number) => {
        setSelectedTab(newValue); // Update the state with the selected tab
    };

    const [selectedAsset, setSelectedAsset] = useState('');

    const handleSelectChange = (event: SelectChangeEvent) => {
        setSelectedAsset(event.target.value);
        dispatch(resetAssetState())
        dispatch(fetchAssetData(`asset/${event.target.value}`))
        navigate('/asset-details/' + event.target.value);
    };

    const tabList = [
        {'icon': <Info />, 'label': 'Metadata'},
        {'icon': <Headphones />, 'label': 'Devices'},
        {'icon': <NetworkCheck />, 'label': 'KPIs'},
        {'icon': <AutoGraph />, 'label': 'Power Curve'},
        {'icon': <Alarm />, 'label': 'Alarms'},
        {'icon': <FlashOn />, 'label': 'Performance'},
        {'icon': <RemoveRedEye />, 'label': 'Curtailment'},
        {'icon': <Thunderstorm />, 'label': 'Whether'},
        {'icon': <Compare />, 'label': 'OEM Comparison'}
    ]

    return (
        <Grid container spacing={2}>
            <Grid size={6} marginBottom={2} marginTop={2}>
                <Typography component="h1" variant="h5">
                    <WindPowerIcon/> {assetDetails?.name}
                </Typography>
            </Grid>
            <Grid size={6}>
                <div className={'flex justify-end items-center'}>
                    <FormControl sx={{ m: 1, minWidth: 200, maxWidth: 200 }} size="small">
                        <InputLabel id="demo-select-small-label">AssetsTab</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectedAsset}
                            label="AssetsTab"
                            onChange={handleSelectChange}>
                            <MenuItem value="" disabled>
                                <em>Select an option</em>
                            </MenuItem>
                            {
                                userAssetsData.map(asset => (
                                    <MenuItem
                                        sx={{minWidth: 200, maxWidth: 400 }}
                                        key={asset.id}
                                        value={asset.id}>
                                        {asset.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </Grid>

            <Grid size={12}>
                <TabComponent
                    onTabChange={handleTabChange}
                    tabList={tabList}
                />
            </Grid>

            {
                selectedTab === 0 &&
                <Grid size={12}>
                    <div style={{height: '60vh', width: '100%'}}>
                        <GoogleMap coords={assetMapData} defaultZoom={12}></GoogleMap>
                    </div>
                </Grid>
            }

            {
                selectedTab === 1 &&
                <Grid size={12}>
                    <Card>
                        <CardContent>
                            <AGDataTable
                                title={'Device Information'}
                                rowData={assetDevicesData}
                                colDefs={columns}
                                defaultColDef={defaultColDef} />
                        </CardContent>
                    </Card>
                </Grid>
            }

        </Grid>
    )
}

export default WindAssetDetails;