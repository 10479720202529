import {Info} from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import React from "react";

export const NoAssetSelected: React.FC = () => {
    return (
        <Grid size={12}>
            <div className="bg-gray-100 flex items-center justify-center">
                <div className="bg-white p-8 rounded-lg shadow-md text-center min-w-full">
                    <Info></Info>
                    <p className="text-gray-600">
                        Please select an asset to monitor.
                    </p>
                </div>
            </div>
        </Grid>
    )
}