import React, {useState} from "react";
import {Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

interface TabComponentProps {
    tabList: {icon: any, label: string, id?: string}[];
    onTabChange: (newValue: number) => void;
    selectedTabNumber?: number;
}

const TabComponent : React.FC<TabComponentProps> = (props: TabComponentProps) => {

    const [value, setValue] = useState(props.selectedTabNumber ? props.selectedTabNumber : 0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        props.onTabChange(newValue);
    };

    return (
        <Grid size={12}>
            <Box className="rounded-lg shadow-md">
                <Tabs
                    value={value}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    textColor="primary"
                    indicatorColor="primary"
                    className="bg-white"
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            display: 'block',
                        },
                        '& .MuiTab-root': {
                            fontSize: '1rem', // Example of using MUI's sx prop
                            textTransform: 'none',
                            padding: '16px 24px',
                            minHeight: 'auto'
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'amber-500', // Customize indicator color (amber-500 from Tailwind)
                        },
                    }}>
                    {
                        props.tabList.map((tab) => (
                            <Tab id={tab.id} key={tab.id} iconPosition="start" icon={<tab.icon/>} label={tab.label} className="text-gray-700 hover:text-gray-900"/>
                        ))
                    }
                </Tabs>
            </Box>
        </Grid>
    )
}

export default TabComponent


