// slices/userSlice.ts
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Asset} from "../interfaces/asset";
import api from "../utils/interceptor";


// Define the initial state type
interface DataState {
    loading: boolean;
    data: Asset; // Replace 'any[]' with the actual type of your data if needed
    error: string | null;
}

const initialState: DataState = {
    loading: false,
    data: {name: null, devices: [], modules: [], asset_type_info: {asset_type: null, id: null}, latitude: null, longitude: null},
    error: null,
};


export const fetchAssetData = createAsyncThunk('data/assetData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return response.data;
});

const assetSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        // This action resets the state back to the initial state
        resetAssetState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAssetData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAssetData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchAssetData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to post data';
            });
    },
});


export const { resetAssetState } = assetSlice.actions;
export default assetSlice.reducer