import * as Highcharts from "highcharts";

const dataAnalysisChartOptions: Highcharts.Options = {
    chart: {
        height: 700,
        type: "spline",
    },
    time: {
        useUTC: false
    },
    rangeSelector: {
        enabled: false,
        selected: 1
    },
    yAxis: [],
    title: {
        text: '',
    },
    legend: {
        enabled: true, // Enable the legend
        layout: 'horizontal', // You can also set it to 'vertical'
        align: 'center',
        verticalAlign: 'bottom', // Position at the bottom of the chart
    },
    tooltip: {
        shared: true,  // Show data for all series in the tooltip when hovered
        split: false,  // If true, tooltips are split into boxes for each series
        xDateFormat: '%Y-%m-%d %H:%M',  // Format for the x-axis (date)
        valueDecimals: 2,  // Number of decimal places for the y-axis values
        useHTML: true,  // Enable HTML in the tooltip
        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Semi-transparent black background
        borderColor: '#FF5733', // Orange border color
        borderRadius: 10, // Rounded corners
        style: {
            color: '#FFFFFF',  // White text
            fontSize: '14px',  // Slightly larger text
        },
        formatter: function () {
            // Convert epoch (x value) to a human-readable date
            const date = new Date(this.x ?? 0);
            const formattedDate = date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
            let tooltipHtml = `<div style="text-align: left;"><b>${formattedDate}</b><br/>`;
            this.points?.forEach(point => {
                // Safely access point.y, use 0 as fallback if null or undefined
                const yValue = point.y?.toFixed(2) ?? '0.00';
                tooltipHtml += `<span style="color: ${point.series.color};">● </span><b>${point.series.name}</b>: ${yValue}<br/>`;
            });
            tooltipHtml += `</div>`;
            return tooltipHtml;
        }
    },
    series: []
};

export default dataAnalysisChartOptions;