// slices/weatherSlice.ts
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from "../utils/interceptor";


// Define the initial state type
interface DataState {
    loading: boolean;
    weatherData: any; // Replace 'any[]' with the actual type of your data if needed
    error: string | null;
}

const initialState: DataState = {
    loading: false,
    weatherData: {},
    error: null,
};


export const fetchWeatherData = createAsyncThunk('data/weatherData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return response.data;
});

const weatherSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        // This action resets the state back to the initial state
        resetWeatherState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWeatherData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWeatherData.fulfilled, (state, action) => {
                state.loading = false;
                state.weatherData = action.payload;
            })
            .addCase(fetchWeatherData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to post data';
            });
    },
});


export const { resetWeatherState } = weatherSlice.actions;
export default weatherSlice.reducer