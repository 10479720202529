// slices/weatherSlice.ts
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from "../utils/interceptor";


// Define the initial state type
interface DataState {
    loading: boolean;
    signalsData: any[]; // Replace 'any[]' with the actual type of your data if needed
    error: string | null;
}

const initialState: DataState = {
    loading: false,
    signalsData: [],
    error: null,
};


export const fetchAssetSignals = createAsyncThunk('data/signalsData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return signalMapper(response.data);
});

const signalMapper = (data: any[]) => {
    return data.map(signal => ({
        displayLabel: signal.signal_display_name,
        isVisible: signal.signal_visibility,
        unit: signal.signal_unit,
        customerName: signal.signal_name,
        internalName: signal.signal_internal_name,
    }));
}

const signalSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        // This action resets the state back to the initial state
        resetSignalState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAssetSignals.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAssetSignals.fulfilled, (state, action) => {
                state.loading = false;
                state.signalsData = action.payload;
            })
            .addCase(fetchAssetSignals.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to post data';
            });
    },
});


export const { resetSignalState } = signalSlice.actions;
export default signalSlice.reducer