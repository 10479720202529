// slices/kpiSlice.ts
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from "../utils/interceptor";
import {KPI} from "../interfaces/kpi";

interface KPIState {
    last15DaysKpiData: {
        data: KPI[],
        loading: boolean;
        error: string | null;
    }
    last7DaysKpiData: {
        data: KPI[],
        loading: boolean;
        error: string | null;
    }
    yesterdayKpiData: {
        data: KPI[],
        loading: boolean;
        error: string | null;
    }
}

// Define the initial state type
interface DataState {
    kpiData: KPIState;
}

const initialState: DataState = {
    kpiData: {
        last7DaysKpiData: {
            data: [],
            loading: false,
            error: null,
        }, yesterdayKpiData: {
            data: [],
            loading: false,
            error: null,
        }, last15DaysKpiData: {
            data: [],
            loading: false,
            error: null,
        }
    },
};

// Thunk to fetch data from an API
export const fetchLast7DaysKpiData = createAsyncThunk('user/fetchLast7DaysKpiData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return await response.data;
});

export const fetchYesterdayKpiData = createAsyncThunk('user/fetchYesterdayKpiData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return await response.data;
});

export const fetchLast15DaysKpiData = createAsyncThunk('user/fetchLast15DaysKpiData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return await response.data;
});


const kpiSlice = createSlice({
    name: 'kpi',
    initialState,
    reducers: {
        resetKpiState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLast7DaysKpiData.pending, (state) => {
                state.kpiData.last7DaysKpiData.loading = true;
                state.kpiData.last7DaysKpiData.error = null;
            })
            .addCase(fetchLast7DaysKpiData.fulfilled, (state, action) => {
                state.kpiData.last7DaysKpiData.loading = false;
                state.kpiData.last7DaysKpiData.data = action.payload;
            })
            .addCase(fetchLast7DaysKpiData.rejected, (state, action) => {
                state.kpiData.last7DaysKpiData.loading = false;
                state.kpiData.last7DaysKpiData.error = action.error.message || 'Failed to fetch data';
            })

            .addCase(fetchYesterdayKpiData.pending, (state) => {
                state.kpiData.yesterdayKpiData.loading = true;
                state.kpiData.yesterdayKpiData.error = null;
            })
            .addCase(fetchYesterdayKpiData.fulfilled, (state, action) => {
                state.kpiData.yesterdayKpiData.loading = false;
                state.kpiData.yesterdayKpiData.data = action.payload;
            })
            .addCase(fetchYesterdayKpiData.rejected, (state, action) => {
                state.kpiData.yesterdayKpiData.loading = false;
                state.kpiData.yesterdayKpiData.error = action.error.message || 'Failed to fetch data';
            })

            .addCase(fetchLast15DaysKpiData.pending, (state) => {
                state.kpiData.last15DaysKpiData.loading = true;
                state.kpiData.last15DaysKpiData.error = null;
            })
            .addCase(fetchLast15DaysKpiData.fulfilled, (state, action) => {
                state.kpiData.last15DaysKpiData.loading = false;
                state.kpiData.last15DaysKpiData.data = action.payload;
            })
            .addCase(fetchLast15DaysKpiData.rejected, (state, action) => {
                state.kpiData.last15DaysKpiData.loading = false;
                state.kpiData.last15DaysKpiData.error = action.error.message || 'Failed to fetch data';
            })
    },
});

export const {resetKpiState} = kpiSlice.actions;

export default kpiSlice.reducer;
