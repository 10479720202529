import {Card, CardContent} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {fetchMetadata} from "../../slices/metadataSlice";
import {Skeleton} from "@mui/lab";

const Metadata: React.FC = () => {
    const textClass = 'text-md font-bold text-gray-700 p-1'
    const dispatch = useDispatch<AppDispatch>()
    const [fleetMetaData, setFleetMetaData] = useState<any>({})
    const {metaData, loading, error} = useSelector((state: RootState) => state.metaData);

    useEffect(() => {
        dispatch(fetchMetadata('user/fleet/metadata'))
    }, []);

    useEffect(() => {
        if(!loading && metaData) {
            setFleetMetaData(metaData)
        }
    }, [loading, metaData]);

    return (
        <Grid size={12}>
            <Card>
                <CardContent sx={{paddingBottom: '16px !important'}}>
                    {
                        loading &&
                        <Skeleton variant={"text"}>
                            <div className={'flex flex-row flex-1 flex-grow space-x-2'}>
                                <div className="transition duration-300">
                                    <h3 className={textClass}>Assets : {fleetMetaData.total_assets}</h3>
                                </div>
                                <div className="transition duration-300">
                                    <h3 className={textClass}>Capacity : {fleetMetaData.total_capacity_mw} MW</h3>
                                </div>
                                <div className="transition duration-300">
                                    <h3 className={textClass}>Countries : {fleetMetaData.total_countries}</h3>
                                </div>
                                <div className="transition duration-300">
                                    <h3 className={textClass}>OEMs : {fleetMetaData.total_oems}</h3>
                                </div>
                                <div className="transition duration-300">
                                    <h3 className={textClass}>Devices : {fleetMetaData.total_devices}</h3>
                                </div>
                            </div>
                        </Skeleton>
                    }
                    {
                        !loading &&
                        <div className={'flex flex-row flex-1 flex-grow space-x-2'}>
                            <div className="transition duration-300">
                                <h3 className={textClass}>Assets : {fleetMetaData.total_assets}</h3>
                            </div>
                            <div className="transition duration-300">
                                <h3 className={textClass}>Capacity : {fleetMetaData.total_capacity_mw} MW</h3>
                            </div>
                            <div className="transition duration-300">
                                <h3 className={textClass}>Countries : {fleetMetaData.total_countries}</h3>
                            </div>
                            <div className="transition duration-300">
                                <h3 className={textClass}>OEMs : {fleetMetaData.total_oems}</h3>
                            </div>
                            <div className="transition duration-300">
                                <h3 className={textClass}>Devices : {fleetMetaData.total_devices}</h3>
                            </div>
                        </div>
                    }
                </CardContent>
            </Card>
        </Grid>
    )
}

export default Metadata;