// src/features/globalSlice.js
import { createSlice } from '@reduxjs/toolkit';
import dayjs, {Dayjs} from "dayjs";

interface FilterState {
    filterData: {
        asset: any;
        devices: any[];
        signals: any[];
        timeFrame: {
            startDate: dayjs.Dayjs | null;
            endDate: dayjs.Dayjs | null;
        }
    },
    filtersLoading: boolean,
    filtersError: string | null
}

const initialState: FilterState = {
    filterData: {
        asset: null,
        devices: [],
        signals: [],
        timeFrame: {
            startDate: null,
            endDate: null,
        }
    },
    filtersLoading: false,
    filtersError: null,
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilters: (state, action) => {
            state.filterData = action.payload;
        },
        resetFilters: (state) => {
            return initialState  // Correctly return the initial state
        },
    },
});

export const { setFilters, resetFilters } = filterSlice.actions;

export default filterSlice.reducer;