import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const Home = () => {
    const navigate = useNavigate(); // useNavigate hook
    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            navigate('/login');
            localStorage.setItem('token', 'XYZ');
        } else {
            navigate('/fleet/overview');
        }
    }, []);
    return (
        <></>
    )
}

export default Home;