import React, {useEffect, useMemo, useState} from "react";
import Grid from '@mui/material/Grid2';
import {Card, CardContent} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import GoogleMap from "../../components/Map";
import {useNavigate} from "react-router-dom";
import KpiWidget from "../../components/KpiWidget";
import PageTileAndBreadCrumb from "../../components/PageTileAndBreadCrumb";
import {MapData} from "../../interfaces/map";
import AGDataTable from "../../components/AGDataTable";
import WindPowerIcon from "@mui/icons-material/WindPower";
import {ColDef} from "ag-grid-enterprise";
import {setSelectedAssetId, setSelectedLHSMenuId} from "../../slices/global";
import OpenWeatherMap from "../../components/WhetherMap";


const WindOverview: React.FC = () => {
    const [assetMapData, setAssetsMapData] = useState<MapData[]>([])
    const [windAssets, setWindAssets] = useState<MapData[]>([])
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const {
        userAssetsData,
        userBasicDataError,
        userAssetsLoading,
    } = useSelector((state: RootState) => state.userData);

    useEffect(() => {
        if (!userAssetsLoading && userAssetsData.length > 0) {
            let temp: MapData[] = []
            userAssetsData.forEach(assetData => {
                const tempMapObj = {
                    name: assetData.name,
                    latitude: assetData.latitude,
                    longitude: assetData.longitude,
                }
                temp.push(tempMapObj)
            });
            setAssetsMapData(temp)
            setWindAssets(userAssetsData)
        }
    }, [userAssetsLoading, userAssetsData]);

    const columns: ColDef[] = [
        {field: 'id', headerName: 'ID', maxWidth: 200, hide: true},
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            minWidth: 300,
            cellRenderer: (params: any) => {
                return (
                    <button
                        onClick={() => {
                            dispatch(setSelectedAssetId(params.data.id));
                            navigate(`/wind/monitoring`)
                        }}>
                        {params.value}
                    </button>
                );
            }
        },
        {field: 'state', headerName: 'State', flex: 1},
        {field: 'currency', headerName: 'Currency', flex: 1},
        {field: 'ppa', headerName: 'PPA', flex: 1},
        {field: 'altitude', headerName: 'Altitude', flex: 1},
        {field: 'total_devices', headerName: 'Total Devices', flex: 1},
        {field: 'asset_timezone', headerName: 'Timezone', flex: 1, minWidth: 200,},
        {field: 'subscribed', headerName: 'Subscribed', flex: 1},
        {field: 'latitude', headerName: 'Latitude', flex: 1},
        {field: 'longitude', headerName: 'Longitude', flex: 1},
        {field: 'role_display_name', headerName: 'Access', flex: 1},
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            editable: true,
            filter: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
        };
    }, []);

    return (
        <Grid container spacing={2}>

            <PageTileAndBreadCrumb title={'Wind Overview'}></PageTileAndBreadCrumb>

            <Grid size={2}>
                <Card>
                    <KpiWidget></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget></KpiWidget>
                </Card>
            </Grid>
            <Grid size={12}>
                <Card>
                    <CardContent>
                        <AGDataTable
                            title={'Sites'}
                            icon={<WindPowerIcon />}
                            rowData={windAssets}
                            colDefs={columns}
                            defaultColDef={defaultColDef} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid size={12}>
                <div style={{height: '50vh', width: '100%'}}>
                    <GoogleMap
                        showInfoWindow={true}
                        infoWindowHeaderContent={'Site Information'}
                        coords={assetMapData}
                        defaultZoom={4}></GoogleMap>
                </div>
            </Grid>
        </Grid>
    )
}

export default WindOverview;