import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    CardContent,
    Card,
    TextField,
    Autocomplete,
    Dialog,
    DialogContent,
    DialogContentText, Popover, Popper
} from '@mui/material';
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store";
import {UserAssetData} from "../interfaces/user";
import {fetchAssetData} from "../slices/assetSlice";
import {Device} from "../interfaces/asset";
import {setFilters, resetFilters} from "../slices/filterSlice"
import {timePresets} from "../data/timePresets";
import {fetchAssetSignals} from "../slices/signalSlice";

import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import Box from "@mui/material/Box";

type AssetDeviceFormProps = {
    showAssets?: boolean;
    showDevices: boolean;
    showSignals: boolean;
    showTimeFrame: boolean;
};

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
        label: 'Yesterday',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(1, 'day'), today.subtract(1, 'day')];
        },
    },
    {
        label: 'Last Week',
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        },
    },
    {
        label: 'Last 7 Days',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(7, 'day'), today.subtract(1, 'day')];
        },
    },
    {
        label: 'Last 30 Days',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(30, 'day'), today.subtract(1, 'day')];
        },
    },
    {
        label: 'MTD',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.subtract(1, 'day')];
        },
    },
    {
        label: 'YTD',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('year'), today.subtract(1, 'day')];
        },
    },
    { label: 'Reset', getValue: () => [null, null] },
];

const FilterFormComponent: React.FC<AssetDeviceFormProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>()
    const [selectedAsset, setSelectedAsset] = useState<UserAssetData | null>(null);
    const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
    const [selectedDevicesNames, setSelectedDevicesNames] = useState<any[]>([]);
    const [selectedSignals, setSelectedSignals] = useState<any[]>([]);
    const [assetSignalsData, setAssetSignalsData] = useState<any[]>([]);
    const [assets, setAssets] = useState<UserAssetData[]>([]);
    const [devices, setDevices] = useState<Device[]>([]);
    const {selectedAssetId} = useSelector((state: RootState) => state.global);
    const [openTimeFrame, setOpenTimeFrame] = useState(false);  // To manage the picker visibility
    const [timeFrame, setTimeFrame] = useState<DateRange<Dayjs>>([null, null]); // To manage the selected date range
    const anchorRef = useRef(null);  // Reference to the input element

    const {
        userAssetsData,
        userBasicDataError,
        userAssetsLoading,
    } = useSelector((state: RootState) => state.userData);

    const {
        data,
        loading,
        error,
    } = useSelector((state: RootState) => state.assetData);

    const {
        signalsData,
        loading: signalsLoading,
        error: signalsError,
    } = useSelector((state: RootState) => state.signalsData);

    useEffect(() => {
        if (userAssetsData.length > 0) {
            setAssets(userAssetsData);
        }
    }, [userAssetsData]);

    useEffect(() => {
        if (data && data.devices.length > 0) {
            setDevices(data.devices);
        }
    }, [data]);

    useEffect(() => {
        setSelectedDevicesNames(selectedDevices.map(device => device.name));
    }, [selectedDevices]);

    useEffect(() => {
        if(!signalsLoading && signalsData.length > 0) {
            setAssetSignalsData(signalsData);
        }
    }, [signalsLoading, signalsData]);

    // Event Handlers with proper typing
    const handleAssetChange = (assetId: any) => {
        const selectedAsset = assets.find(asset => asset.id === assetId);
        if (selectedAsset) {
            setDevices([])
            setSelectedAsset(selectedAsset);
            dispatch(fetchAssetData('asset/' + selectedAsset.id))
            dispatch(fetchAssetSignals('signals/asset/' + selectedAsset.id))
        } else {
            setDevices([])
            setAssetSignalsData([])
            setSelectedAsset(null);
        }
    };

    useEffect(() => {
        if (selectedAssetId && assets.length > 0) {
            handleAssetChange(selectedAssetId)
        }
    }, [selectedAssetId, assets]);


    const handleDeviceChange = (event: any) => {
        if (event.find((device: any) => device.name == 'Select All')) {
            setSelectedDevices([])

            setTimeout(() => {
                setSelectedDevices(devices);
            }, 100)

        } else {
            setSelectedDevices(event);
        }

    };

    const handleSignalChange = (event: any) => {
        setSelectedSignals(event);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(
            setFilters(
                {
                    assets: selectedAsset,
                    devices: selectedDevices,
                    signals: selectedSignals,
                    timeFrame: {
                        startDate: timeFrame[0] ? timeFrame[0] : null,
                        endDate: timeFrame[1] ? timeFrame[1] : null,
                    }
                }
            )
        );
    };

    const handleCancel = () => {
        setSelectedAsset(null);
        setSelectedSignals([])
        setSelectedDevices([]);
        setTimeFrame([null, null]);
        dispatch(resetFilters())
    };

    useEffect(() => {
        return () => {
            dispatch(resetFilters())
        }
    }, [dispatch]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Handle focus/click on the input element
    const handleTimeFrameClick = () => {
        setOpenTimeFrame(true);  // Open the popover when input is clicked
    };

    // Handle close of the popover
    const handleTimeFrameClose = () => {
        setOpenTimeFrame(false);  // Close the popover
    };

    const open = Boolean(anchorEl);

    return (
        <Card>
            <CardContent>
                <div className={"mb-4 flex flex-row justify-between align-center items-center"}>
                    <Typography variant={'h6'}>Filters: </Typography>
                </div>
                <form onSubmit={handleSubmit} className={"d-block"}>
                    <div className={"flex flex-row space-x-4 justify-items-start align-center items-end"}>
                        {
                            props.showDevices &&
                            <Autocomplete
                                aria-required={true}
                                limitTags={1}
                                multiple
                                onChange={(event: any, newValue: any | null) => handleDeviceChange(newValue)}
                                value={selectedDevices}
                                disabled={devices?.length === 0}
                                size="small"
                                sx={{
                                    '& .MuiAutocomplete-inputRoot': {
                                        padding: '0px', // Ensure no padding causes height changes
                                        minHeight: '40px', // Set a fixed height for the input
                                        alignItems: 'center' // Align input content properly
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        height: '40px', // Ensure the outline stays at a fixed height
                                    },
                                    '& .MuiAutocomplete-tag': {
                                        maxHeight: '30px', // Limit the height of each selected tag
                                        overflow: 'hidden', // Prevent the text from increasing height
                                    },
                                    minWidth: 200,
                                    maxWidth: 200,
                                }}
                                options={[{name: 'Select All'}, ...devices]}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Devices"/>
                                )}
                            />
                        }

                        {
                            props.showSignals &&
                            <Autocomplete
                                aria-required={true}
                                limitTags={1}
                                multiple
                                value={selectedSignals}
                                onChange={(event: any, newValue: any | null) => handleSignalChange(newValue)}
                                disabled={assetSignalsData.length === 0}
                                size="small"
                                sx={{
                                    '& .MuiAutocomplete-inputRoot': {
                                        padding: '0px', // Ensure no padding causes height changes
                                        minHeight: '40px', // Set a fixed height for the input
                                        alignItems: 'center' // Align input content properly
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        height: '40px', // Ensure the outline stays at a fixed height
                                    },
                                    '& .MuiAutocomplete-tag': {
                                        maxHeight: '30px', // Limit the height of each selected tag
                                        overflow: 'hidden', // Prevent the text from increasing height
                                    },
                                    minWidth: 300,
                                    maxWidth: 300,
                                }}
                                options={assetSignalsData}
                                disableCloseOnSelect
                                getOptionLabel={(option) => {
                                    return option.displayLabel + ' (' + option.unit + ')'
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Signals"/>
                                )}
                            />
                        }

                        {
                            props.showTimeFrame &&
                            <div className="flex flex-col">
                                <Box sx={{ width: 250 }}>
                                    {/* Input field to trigger the Date Range Picker */}
                                    <TextField
                                        label="Select Date Range"
                                        value={timeFrame[0] && timeFrame[1] ? `${timeFrame[0]?.format('YYYY-MM-DD')} - ${timeFrame[1]?.format('YYYY-MM-DD')}` : ''}
                                        fullWidth
                                        InputLabelProps={{
                                            sx: {
                                                top: '-7px',  // Adjust label position when inactive
                                                // fontSize: '14px',  // Set label size
                                            },
                                        }}
                                        InputProps={{
                                            sx: {
                                                height: '40px',  // Fixed input height
                                                padding: '0 5px',  // Ensure padding inside input
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'grey',  // Customize border color if needed
                                                },
                                            },
                                        }}
                                        sx={{
                                            '& .MuiInputLabel-shrink': {
                                                transform: 'translate(14px, -4px) scale(0.75)',  // Adjust shrink label position
                                            },
                                        }}
                                        variant={'outlined'}
                                        inputProps={{ readOnly: true }} // Prevent typing in the input
                                        onClick={handleTimeFrameClick}  // Show picker on input click
                                        inputRef={anchorRef}  // Attach ref to the input to position the popover
                                    />

                                    <Popover
                                        open={openTimeFrame}
                                        onClose={handleTimeFrameClose}
                                        anchorEl={anchorRef.current}  // Attach the popover to the input field
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <StaticDateRangePicker
                                                maxDate={dayjs()}
                                                displayStaticWrapperAs={'mobile'}
                                                value={timeFrame}
                                                onChange={(newValue) => setTimeFrame(newValue)}  // Handle date selection
                                                onClose={handleTimeFrameClose}  // Close when the user finishes
                                                slotProps={{
                                                    shortcuts: {
                                                        items: shortcutsItems,
                                                    },
                                                    actionBar: { actions: [] },
                                                }}
                                                calendars={2}
                                            />
                                        </LocalizationProvider>
                                    </Popover>
                                </Box>
                            </div>
                        }

                        <div className="flex space-x-4">
                            <Button variant="contained" color="primary" type="submit"
                                    disabled={(props.showSignals && selectedSignals.length === 0) || selectedDevices.length === 0 || timeFrame[0] == null}>
                                Submit
                            </Button>
                            <Button variant="contained" color="secondary" onClick={handleCancel}>
                                Reset
                            </Button>
                            <Button variant="outlined" onClick={handleClick}>View Summary</Button>
                        </div>
                    </div>
                </form>
                <Dialog
                    open={open}
                    maxWidth={'md'}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {
                                props.showAssets &&
                                <div className="mt-2 mb-2 flex flex-row">
                                    <h3 className="font-medium text-gray-700 mr-1">Asset:</h3>
                                    <p className="text-gray-600">{selectedAsset?.name}</p>
                                </div>
                            }
                            {
                                props.showDevices &&
                                <div className="mb-2 flex flex-row">
                                    <h3 className="font-medium text-gray-700 mr-1">Devices :</h3>
                                    <p className="text-gray-600">{selectedDevicesNames.join(', ')}</p>
                                </div>
                            }
                            {
                                props.showSignals &&
                                <div className="mb-2 flex flex-row">
                                    <h3 className="font-medium text-gray-700 mr-1">Signals :</h3>
                                    <p className="text-gray-600">{selectedSignals.map(signal => signal.displayLabel).join(', ')}</p>
                                </div>
                            }
                            <div className="flex flex-row">
                                <h3 className="font-medium text-gray-700 mr-1">TimeFrame :</h3>
                                <p className="text-gray-600">{timeFrame[0]?.format('YYYY-MM-DD') + ' - ' + timeFrame[1]?.format('YYYY-MM-DD')}</p>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default FilterFormComponent;