// slices/weatherSlice.ts
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from "../utils/interceptor";


// Define the initial state type
interface DataState {
    loading: boolean;
    statusData: any[]; // Replace 'any[]' with the actual type of your data if needed
    error: string | null;
}

const initialState: DataState = {
    loading: false,
    statusData: [],
    error: null,
};


export const fetchDevicesStatus = createAsyncThunk('data/statusData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return signalMapper(response.data);
});

const signalMapper = (data: any[]) => {
    let percentage;

    data.forEach((dataPoint: any) => {
        if (dataPoint.estimated_power && dataPoint.active_power > 0) {
            percentage = (dataPoint.active_power / dataPoint.estimated_power) * 100;
        } else {
            percentage = 0;
        }

        dataPoint['power_percentage'] = percentage;
    })

    return data
        .map(statusDataPoint => ({
            latestTimeStamp: statusDataPoint.latest_time_stamp_asset,
            assetName: statusDataPoint.asset_name,
            deviceName: statusDataPoint.device_name,
            estimatedPower: statusDataPoint.estimated_power,
            windDirection: statusDataPoint.wind_direction < 0 ? (statusDataPoint.wind_direction + 360) % 360 : statusDataPoint.wind_direction,
            temperature: statusDataPoint.temperature,
            windSpeed: statusDataPoint.wind_speed ? statusDataPoint.wind_speed : null,
            activePower: statusDataPoint.active_power ? statusDataPoint.active_power : 'N/A',
            status: statusDataPoint.status_label,
            statusColor: statusDataPoint.status_color,
            available: statusDataPoint.available,
            last24Hours: statusDataPoint.last24HoursStatus,
            powerPercentage: statusDataPoint.power_percentage,
        }))
        .sort((a, b) => {
            // First, sort by available (descending if available is boolean)
            if (a.available !== b.available) {
                return a.available - b.available; // If available is true, it will be prioritized
            }

            // Then, sort by powerPercentage (descending)
            return a.powerPercentage - b.powerPercentage;
        });
}

const statusSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        // This action resets the state back to the initial state
        resetStatusState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDevicesStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDevicesStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.statusData = action.payload;
            })
            .addCase(fetchDevicesStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to post data';
            });
    },
});


export const { resetStatusState } = statusSlice.actions;
export default statusSlice.reducer