// slices/userSlice.ts
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from "../utils/interceptor";

// Define the initial state type
interface DataState {
    loading: boolean;
    data: {access_token: string, token_type: string}; // Replace 'any[]' with the actual type of your data if needed
    error: string | null;
}

const initialState: DataState = {
    loading: false,
    data: {access_token: '', token_type: ''},
    error: null,
};


export const postData = createAsyncThunk('data/postData', async (formData: FormData) => {
    const response = await api.post('token', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',  // This is necessary for file uploads
        },
    });
    return await response.data; // Assuming the response contains the newly created data
});

const loginSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        // This action resets the state back to the initial state
        resetLoginState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(postData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(postData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(postData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to post data';
            });
    },
});


export const { resetLoginState } = loginSlice.actions;
export default loginSlice.reducer