import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {fetchAssetData, resetAssetState} from "../../slices/assetSlice";
import {Asset, Device} from "../../interfaces/asset";
import Grid from "@mui/material/Grid2";
import {
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import AGDataTable from "../../components/AGDataTable";
import {ColDef} from "ag-grid-enterprise";
import Typography from "@mui/material/Typography";
import GoogleMap from "../../components/Map";
import {MapData} from "../../interfaces/map";
import WindPowerIcon from '@mui/icons-material/WindPower';
import {
    Alarm,
    AutoGraph,
    Compare,
    FlashOn,
    Headphones,
    Info,
    NetworkCheck,
    RemoveRedEye,
    Thunderstorm
} from "@mui/icons-material";
import TabComponent from "../../components/Tabs";

const WindDeviceDetails: React.FC = () => {
    const {asset_id} = useParams<{ asset_id: string }>();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    const [assetDetails, setAssetDetails] = useState<Asset>();
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const {userAssetsData} = useSelector((state: RootState) => state.userData);

    useEffect(() => {
        dispatch(fetchAssetData(`asset/${asset_id}`))
    }, []);

    const handleTabChange = (newValue: number) => {
        setSelectedTab(newValue); // Update the state with the selected tab
    };

    const [selectedAsset, setSelectedAsset] = useState('');

    const handleSelectChange = (event: SelectChangeEvent) => {
        setSelectedAsset(event.target.value);
        dispatch(resetAssetState())
        navigate('/device-details/' + event.target.value);
    };

    const tabList = [
        {'icon': <Info/>, 'label': 'Metadata'},
        {'icon': <NetworkCheck/>, 'label': 'KPIs'},
        {'icon': <AutoGraph/>, 'label': 'Power Curve'},
        {'icon': <Alarm/>, 'label': 'Alarms'},
        {'icon': <FlashOn/>, 'label': 'Performance'},
        {'icon': <RemoveRedEye/>, 'label': 'Curtailment'},
        {'icon': <Thunderstorm/>, 'label': 'Whether'},
        {'icon': <Compare/>, 'label': 'OEM Comparison'}
    ]

    return (
        <Grid container spacing={2}>
            <Grid size={6} marginBottom={2} marginTop={2}>
                <Typography component="h1" variant="h5">
                    <WindPowerIcon/> {assetDetails?.name}
                </Typography>
            </Grid>
            <Grid size={6}>
                <div className={'flex justify-end items-center'}>
                    <FormControl sx={{m: 1, minWidth: 200, maxWidth: 200}} size="small">
                        <InputLabel id="demo-select-small-label">AssetsTab</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectedAsset}
                            label="AssetsTab"
                            onChange={handleSelectChange}>
                            <MenuItem value="" disabled>
                                <em>Select an option</em>
                            </MenuItem>
                            {
                                userAssetsData.map(asset => (
                                    <MenuItem
                                        sx={{minWidth: 200, maxWidth: 400}}
                                        key={asset.id}
                                        value={asset.id}>
                                        {asset.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </Grid>

            <Grid size={12}>
                <TabComponent
                    onTabChange={handleTabChange}
                    tabList={tabList}
                />
            </Grid>

            {
                selectedTab === 0 &&
                <Grid size={12}>
                    <Card>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Grid>
            }

            {
                selectedTab === 1 &&
                <Grid size={12}>
                    <Card>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Grid>
            }

            {
                selectedTab === 2 &&
                <Grid size={12}>
                    <Card>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Grid>
            }

            {
                selectedTab === 3 &&
                <Grid size={12}>
                    <Card>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Grid>
            }

        </Grid>
    )
}

export default WindDeviceDetails;