import React, {useEffect, useState} from "react";
import Grid from '@mui/material/Grid2';
import {Card, CardContent} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {fetchUserAssetsData, fetchUserData} from "../../slices/userSlice";
import GoogleMap from "../../components/Map";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import KpiWidget from "../../components/KpiWidget";



const SolarOverview: React.FC = () => {
    const [userAssets, setUsersAssets] = useState([])
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const {userAssetsData, userBasicData, userBasicDataError, userAssetsError, userAssetsLoading, userBasicDataLoading} = useSelector((state: RootState) => state.userData);

    useEffect(() => {
        if (!userBasicDataLoading && userBasicData) {
            dispatch(fetchUserAssetsData(`/users/${userBasicData.id}/assets`))
        }
    }, [userBasicDataLoading, userBasicData]);

    useEffect(() => {
        if(!userAssetsLoading && userAssetsData.length > 0) {
            setUsersAssets([])
        }
    }, [userAssets, userAssetsLoading]);

    useEffect(() => {
        dispatch(fetchUserData('users/me'))
    }, []);

    if (userBasicDataError) {
        navigate('/login');
    }

    return (
        <Grid container spacing={2}>
            <Grid size={4}>
                <Typography component="h1" variant="h5">
                    Fleet - Overview
                </Typography>
            </Grid>
            <Grid size={8} className={'text-right'}>

            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget></KpiWidget>
                </Card>
            </Grid>
            <Grid size={2}>
                <Card>
                    <KpiWidget></KpiWidget>
                </Card>
            </Grid>
            <Grid size={12}>
                <div style={{height: '60vh', width: '100%'}}>
                    <GoogleMap coords={userAssets} defaultZoom={3}></GoogleMap>
                </div>
            </Grid>
        </Grid>
    )
}

export default SolarOverview;