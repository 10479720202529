// src/api.js
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,  // Replace with your API base URL
    timeout: 10000
});

// Optional: You can still use interceptors if you want to handle things globally
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        // Optional global error handling
        if (error.response && error.response.status === 401) {
            localStorage.setItem('global', JSON.stringify({'assetType': 'fleet'}));
            localStorage.removeItem('token');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default api;