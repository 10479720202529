// Import the necessary icons
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import MonitorIcon from '@mui/icons-material/Monitor';
import Insights from '@mui/icons-material/Insights';
import Scale from '@mui/icons-material/Scale';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { ElementType } from 'react';
import {BatchPrediction, Handyman, AttachMoney} from "@mui/icons-material";

// Define a TypeScript type for the menu items
type MenuItem = {
    title: string;
    url: string;
    id: string;
    icon: ElementType; // ReactNode is used for JSX elements like icons
};

// Define the menu array with proper types
export const windLeftHandSideMenu: MenuItem[] = [
    {
        title: 'Overview',
        url: '/wind/overview',
        id: 'wind_overview',
        icon: RemoveRedEyeOutlinedIcon
    },
    {
        title: 'Monitor',
        id: 'wind_monitor',
        url: '/wind/monitoring',
        icon: MonitorIcon
    },
    {
        title: 'Analysis',
        id: 'wind_analysis',
        url: '/wind/analysis',
        icon: Insights
    },
    {
        title: 'Performance',
        id: 'wind_performance',
        url: '/wind/performance',
        icon: Scale
    },
    {
        title: 'Reports',
        id: 'wind_reports',
        url: '/wind/reports',
        icon: ArticleOutlinedIcon
    },
    {
        title: 'Predict',
        id: 'wind_predict',
        url: '/wind/predict',
        icon: BatchPrediction
    },
    {
        title: 'Maintenance',
        id: 'wind_maintenance',
        url: '/wind/maintenance',
        icon: Handyman
    },
    {
        title: 'Finance',
        id: 'wind_finance',
        url: '/wind/finance',
        icon: AttachMoney
    }
];