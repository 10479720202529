// reducers/index.ts
import { combineReducers } from 'redux';
import loginReducer from './loginSlice';
import userReducer from './userSlice';
import assetReducer from './assetSlice';
import kpiReducer from './kpiSlice';
import assetCrudSlice from './assetCrudSlice';
import globalSlice from './global'
import userCrudSlice from "./userCrudSlice";
import dataAnalysisSlice from "./analysisSlice"
import dataAvailabilitySlice from "./dataAvailabilitySlice";
import filterSlice from "./filterSlice";
import weatherSlice from "./weatherSlice";
import metadataSlice from "./metadataSlice";
import signalSlice from "./signalSlice";
import pcSlice from "./powerCurveSlice"
import statusSlice from "./statusSlice"

const rootReducer = combineReducers({
    userData: userReducer,
    loginData: loginReducer,
    assetData: assetReducer,
    kpiData: kpiReducer,
    assetCrudData: assetCrudSlice,
    userCrudData: userCrudSlice,
    global: globalSlice,
    rawData: dataAnalysisSlice,
    availabilityData: dataAvailabilitySlice,
    filterData: filterSlice,
    weatherData: weatherSlice,
    metaData: metadataSlice,
    signalsData: signalSlice,
    powerCurveData: pcSlice,
    liveData: statusSlice
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;