// store.ts
import {configureStore} from '@reduxjs/toolkit'
import rootReducer from './slices';
import storage from 'redux-persist/lib/storage'; // Use localStorage as default storage
import { persistReducer, persistStore } from 'redux-persist';

// Only persist user and settings slices
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['global', 'userData'],  // Only persist specific slices of state
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with the persisted reducer
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,  // Disable serializability check for non-serializable data in redux-persist
        }),
});

const persistor = persistStore(store);


/// Define RootState and AppDispatch types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };