import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Grid2";
import FilterFormComponent from "../../../components/Filters";
import {Card, CardContent} from "@mui/material";
import {HighchartsReact} from "highcharts-react-official";
import Highcharts from "highcharts";
import dataAnalysisHeatMapChartOptions from "../../../data/chartOptions/data_analysis_heatmap_chart_options";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../store";
import {Alert} from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import {fetchDataAvailabilityData, resetDataAvailabilityDataState} from "../../../slices/dataAvailabilitySlice";
import {Info} from "@mui/icons-material";
import {resetFilters} from "../../../slices/filterSlice";

const DataAvailability: React.FC = () => {
    const {filterData, filtersLoading, filtersError} = useSelector((state: RootState) => state.filterData)
    const {dataAvailabilityData, dataAvailabilityLoading, dataAvailabilityError} = useSelector((state: RootState) => state.availabilityData)
    const dataAnalysisHeatMapChartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false)
    const [showInitialMessage, setShowInitialMessage] = useState<boolean>(true)
    const [showNoDataMessage, setShowNoDataMessage] = useState<boolean>(false)
    const [chartOptions, setChartOptions] = useState<any>(dataAnalysisHeatMapChartOptions)
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [userMessage, setUserMessage] = useState<{ message: string | null, alertSeverity: any }>({
        message: "",
        alertSeverity: ""
    });
    const dispatch = useDispatch<AppDispatch>()

    const convertJsonToHeatmapData = (jsonData: any[]) => {
        // Extract unique device IDs and dates to map them to x and y axes
        jsonData.forEach(item => {
            if (item.device_name == null) {
                item.device_name = filterData.devices.find(device => device.id == item.device_id).name
            }
        })
        const devices = [...new Set(jsonData.map(item => item.device_name))];
        const dates = [...new Set(jsonData.map(item => item.date))];

        // Create the 3D array for heatmap (in the format [x, y, value])
        const heatmapData: any[][] = [];

        jsonData.forEach((item: any) => {
            const x = devices.indexOf(item.device_name);  // x-axis is device_id
            const y = dates.indexOf(item.date);         // y-axis is date
            const value = item.total_count ? item.total_count : 0;             // value to be plotted, change to active_power_count if needed
            heatmapData.push([x, y, value]);
        });

        return {
            categoriesX: devices,   // X-axis categories (device_id)
            categoriesY: dates,     // Y-axis categories (dates)
            data: heatmapData       // Heatmap data in [x, y, value] format
        };
    }

    useEffect(() => {
        if (!filtersLoading && filterData && filterData.devices.length > 0) {
            clearSeries();
            dispatch(fetchDataAvailabilityData(
                `wind-data-availability?start_date=${filterData.timeFrame.startDate?.format('YYYY-MM-DD')}&end_date=${filterData.timeFrame.endDate?.format('YYYY-MM-DD')}&device_ids=${filterData.devices.map(item => item.id)}`
            ))
        } else {
            setShowNoDataMessage(false)
            setShowInitialMessage(true);
            setIsDataAvailable(false);
        }
    }, [filtersLoading, filterData]);

    // Function to clear all series
    const clearSeries = () => {
        setChartOptions((prevOptions: any) => ({
            ...prevOptions,
            xAxis: {...prevOptions.xAxis, categories: []},
            yAxis: {...prevOptions.yAxis, categories: []},
            series: [], // Clear the series
        }));
    };

    useEffect(() => {
        clearSeries();
        if (!dataAvailabilityLoading && dataAvailabilityData && dataAvailabilityData.length > 0) {
            setIsDataAvailable(true);
            setShowInitialMessage(false)
            const heatmapResult = convertJsonToHeatmapData(JSON.parse(JSON.stringify(dataAvailabilityData)));

            const newSeries = {
                borderWidth: 1,
                boostThreshold: 100,
                turboThreshold: Number.MAX_VALUE,
                dataLabels: {
                    enabled: false,
                    color: '#000000'
                },
                data: heatmapResult.data
            }

            setChartOptions((prevOptions: any) => ({
                ...prevOptions,
                chart: {...prevOptions.chart, height: (heatmapResult.categoriesY.length - 1) < 10 ? (heatmapResult.categoriesY.length - 1) * 50 : (heatmapResult.categoriesY.length - 1) * 30},
                xAxis: {...prevOptions.xAxis, categories: heatmapResult.categoriesX},
                yAxis: {
                    ...prevOptions.yAxis,
                    min: 0,
                    gridLineWidth: 0.5,  // Reduce grid line thickness for better performance
                    tickLength: 0,  // Optional: Hide ticks for cleaner appearance
                    scrollbar: {enabled: true},
                    max: heatmapResult.categoriesY.length - 1,
                    categories: heatmapResult.categoriesY
                },
                series: [...prevOptions.series, newSeries], // Add new series
            }));
        }
    }, [dataAvailabilityLoading, dataAvailabilityData])

    useEffect(() => {
        if (dataAvailabilityError) {
            setUserMessage({message: dataAvailabilityError, alertSeverity: "error"});
            setOpenSnackBar(true);
        }
    }, [dataAvailabilityError]);

    useEffect(() => {
        return () => {
            dispatch(resetDataAvailabilityDataState())
            dispatch(resetFilters())
            setIsDataAvailable(false);
            setShowInitialMessage(true)
            setShowNoDataMessage(false)
        }
    }, []);

    return (
        <>
            <Grid size={12}>
                <FilterFormComponent
                    showDevices={true}
                    showSignals={false}
                    showTimeFrame={true} />
            </Grid>

            {
                showInitialMessage &&
                <Grid size={12}>
                    <Card>
                        <CardContent sx={{paddingBottom: '16px !important'}}>
                            <div className={'flex justify-center align-items-center items-center'}>
                                <div className={'flex flex-col justify-center align-items-center items-center'}>
                                    <Info fontSize={'small'}></Info>
                                    <span>Please select filters and click submit</span>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            }

            {
                showNoDataMessage &&
                <Grid size={12}>
                    <Card>
                        <CardContent sx={{paddingBottom: '16px !important'}}>
                            <div className={'flex justify-center align-items-center items-center'}>
                                <div className={'flex flex-col justify-center align-items-center items-center'}>
                                    <Info fontSize={'small'}></Info>
                                    <span>No Data</span>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            }

            {
                isDataAvailable &&
                <Grid size={12}>
                    <Card>
                        <CardContent>
                            <HighchartsReact
                                highcharts={Highcharts}
                                ref={dataAnalysisHeatMapChartComponentRef}
                                options={chartOptions}>
                            </HighchartsReact>
                        </CardContent>
                    </Card>
                </Grid>
            }

            <Snackbar open={openSnackBar}
                      autoHideDuration={6000}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                      onClose={() => setOpenSnackBar(false)}>
                <Alert
                    onClose={() => setOpenSnackBar(false)}
                    severity={userMessage.alertSeverity}
                    variant="filled"
                    sx={{width: '100%'}}>
                    {userMessage.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default DataAvailability