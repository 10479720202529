import {
    Airplay,
    Alarm,
    AutoGraph,
    Domain,
    Headphones,
    Info,
    ManageAccounts,
    Subscriptions,
    VerifiedUser
} from "@mui/icons-material";

export const tabList = [
    {'icon': ManageAccounts, 'label': 'Users'},
    {'icon': Domain, 'label': 'Assets'},
    {'icon': Airplay, 'label': 'Devices'},
    {'icon': Subscriptions, 'label': 'Subscriptions'},
    {'icon': AutoGraph, 'label': 'Power Curve'},
    {'icon': Alarm, 'label': 'Alarms'}
]