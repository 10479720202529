// slices/userSlice.ts
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {User, UserAssetData} from "../interfaces/user";
import api from "../utils/interceptor";

// Define the initial state type
interface DataState {
    userBasicDataLoading: boolean;
    userBasicData: User // Replace 'any[]' with the actual type of your data if needed
    userBasicDataError: string | null;
    userAssetsData: UserAssetData[]
    userAssetsLoading: boolean
    userAssetsError: string | null;
    userSubscriptionDataLoading: boolean;
    userSubscriptionError: string | null;
    userSubscriptionData: any[]
}

const initialState: DataState = {
    userBasicDataLoading: false,
    userBasicData: {
        username: '',
        email: '',
        full_name: '',
        phone_number: '',
        customer: {
            name: '',
            contact_person_email: '',
            contact_person_number: '',
            address: '',
            city: '',
            state: '',
            country: '',
            postal_code: '',
            id: null
        },
        id: null
    },
    userAssetsData: [],
    userBasicDataError: null,
    userAssetsLoading: false,
    userAssetsError: null,
    userSubscriptionDataLoading: false,
    userSubscriptionError: null,
    userSubscriptionData: [],
};

// Thunk to fetch data from an API
export const fetchUserData = createAsyncThunk('user/fetchUserData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return await response.data;
});


export const fetchUserAssetsData = createAsyncThunk('user/fetchUserAssetsData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return await response.data;
});

export const fetchUserSubscriptionData = createAsyncThunk('user/fetchUserSubscriptionData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return await response.data;
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUserState: (state) => initialState,
        resetUserSubscriptionData: (state) => {
            return {...state, userSubscriptionData: []}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state) => {
                state.userBasicDataLoading = true;
                state.userBasicDataError = null;
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.userBasicDataLoading = false;
                state.userBasicData = action.payload;
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.userBasicDataLoading = false;
                state.userBasicDataError = action.error.message || 'Failed to fetch data';
            })
            .addCase(fetchUserAssetsData.pending, (state) => {
                state.userAssetsLoading = true;
                state.userAssetsError = null;
            })
            .addCase(fetchUserAssetsData.fulfilled, (state, action) => {
                state.userAssetsLoading = false;
                state.userAssetsData = action.payload;
            })
            .addCase(fetchUserAssetsData.rejected, (state, action) => {
                state.userAssetsLoading = false;
                state.userAssetsError = action.error.message || 'Failed to fetch data';
            })
            .addCase(fetchUserSubscriptionData.pending, (state) => {
                state.userSubscriptionDataLoading = true;
                state.userSubscriptionError = null;
            })
            .addCase(fetchUserSubscriptionData.fulfilled, (state, action) => {
                state.userSubscriptionDataLoading = false;
                state.userSubscriptionData = action.payload;
            })
            .addCase(fetchUserSubscriptionData.rejected, (state, action) => {
                state.userSubscriptionDataLoading = false;
                state.userSubscriptionError = action.error.message || 'Failed to fetch data';
            });
    },
});

export const { resetUserState, resetUserSubscriptionData } = userSlice.actions;

export default userSlice.reducer;
