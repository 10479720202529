import React, {useEffect, useRef, useState} from "react";
import {HighchartsReact} from "highcharts-react-official";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../store";
import performancePCChartOptions from "../../../data/chartOptions/performance_pc_chart_options";
import {fetchPowerCurveData} from "../../../slices/powerCurveSlice";
import FilterFormComponent from "../../../components/Filters";
import Grid from "@mui/material/Grid2";
import {Card, CardContent} from "@mui/material";
import {Info} from "@mui/icons-material";
import Highcharts from "highcharts";
import Snackbar from "@mui/material/Snackbar";
import {Alert} from "@mui/lab";

const WindLossAnalysisTab: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const {selectedAssetId} = useSelector((state: RootState) => state.global);
    const {filterData, filtersLoading, filtersError} = useSelector((state: RootState) => state.filterData)
    const chart = useRef<HighchartsReact.RefObject>(null);
    const [showInitialMessage, setShowInitialMessage] = useState<boolean>(true)
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [chartOptions, setChartOptions] = useState(performancePCChartOptions);
    const [userMessage, setUserMessage] = useState<{ message: string | null, alertSeverity: any }>({
        message: "",
        alertSeverity: ""
    });

    let pcChart = chart.current?.chart;

    // Function to clear all series
    const clearSeries = () => {
        setChartOptions((prevOptions: any) => ({
            ...prevOptions,
            series: [], // Clear the series
        }));
    };

    useEffect(() => {
        if (filterData && filterData.devices.length > 0) {
            setShowInitialMessage(false);
            pcChart?.showLoading()
        }
    }, [filterData]);

    return (
        <>
            <Grid size={12}>
                <FilterFormComponent
                    showAssets={false}
                    showDevices={true}
                    showSignals={false}
                    showTimeFrame={true} />
            </Grid>

            <Grid size={12}>
                {
                    showInitialMessage &&
                    <Card>
                        <CardContent sx={{paddingBottom: '16px !important'}}>
                            <div className={'flex justify-center align-items-center items-center'}>
                                <div className={'flex flex-col justify-center align-items-center items-center'}>
                                    <Info fontSize={'small'}></Info>
                                    <span>Please select filters and click submit</span>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                }
                {
                    !showInitialMessage &&
                    <>
                        <Card>
                            <CardContent>
                                <HighchartsReact
                                    ref={chart}
                                    highcharts={Highcharts}
                                    options={chartOptions}>
                                </HighchartsReact>
                            </CardContent>
                        </Card>
                    </>
                }
            </Grid>

            <Snackbar open={openSnackBar}
                      autoHideDuration={6000}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                      onClose={() => setOpenSnackBar(false)}>
                <Alert
                    onClose={() => setOpenSnackBar(false)}
                    severity={userMessage.alertSeverity}
                    variant="filled"
                    sx={{width: '100%'}}>
                    {userMessage.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default WindLossAnalysisTab;