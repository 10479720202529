export const formatNumber = (num: number) => {
    if (num) {
        if (Math.abs(num) >= 1.0e9) {
            return (num / 1.0e9).toFixed(2) + "B"; // Billions
        } else if (Math.abs(num) >= 1.0e6) {
            return (num / 1.0e6).toFixed(2) + "M"; // Millions
        } else if (Math.abs(num) >= 1.0e3) {
            return (num / 1.0e3).toFixed(2) + "K"; // Thousands
        } else {
            return num.toFixed(2); // Less than thousand, no abbreviation
        }
    } else {
        return 'N/A'
    }
}

export const convertToGWh = (value: number) => {
    return (value / 6000000).toFixed(2);
}

export const calculateCF = (numerator: number, denominator: number) => {
    if (numerator && denominator) return ((numerator / denominator) * 100)?.toFixed(2);
}

export const calculateAvailabilities = (numerator: number, denominator: number) => {
    if (numerator && denominator) return ((numerator / denominator) * 100)?.toFixed(2);
}