import dayjs from 'dayjs';
const today = dayjs()
const yesterday = today.subtract(1, 'day');

// Utility function to get the start and end dates of the last 7 days
export function getLast7DaysDetails () {
    const dates = [];

    // Loop through the last 7 days
    for (let i = 0; i < 7; i++) {
        const currentDate = today.subtract(i, 'day');
        const startOfDay = currentDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endOfDay = currentDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');

        dates.push({
            start: startOfDay,
            end: endOfDay
        });
    }

    return dates;
}

export function getYesterday () {
    return [yesterday.format('YYYY-MM-DD'), yesterday.format('YYYY-MM-DD')];
}

export function getLast7Days () {
    const last7Day = yesterday.subtract(6, 'day');
    return [last7Day.format('YYYY-MM-DD'), yesterday.format('YYYY-MM-DD')];
}

export function getLast15Days () {
    const last15Day = yesterday.subtract(14, 'day');
    return [last15Day.format('YYYY-MM-DD'), yesterday.format('YYYY-MM-DD')];
}

export function getLast30Days () {
    const last15Day = yesterday.subtract(29, 'day');
    return [last15Day.format('YYYY-MM-DD'), yesterday.format('YYYY-MM-DD')];
}

export function getLast60Days () {
    const last15Day = yesterday.subtract(59, 'day');
    return [last15Day.format('YYYY-MM-DD'), yesterday.format('YYYY-MM-DD')];
}

export function getLast90Days () {
    const last15Day = yesterday.subtract(89, 'day');
    return [last15Day.format('YYYY-MM-DD'), yesterday.format('YYYY-MM-DD')];
}

export const epochConversion = (timeStamp: string) => {
    const date = new Date(timeStamp);
    const epochMilliseconds = date.getTime();
    return Math.floor(epochMilliseconds)
}